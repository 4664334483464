.tabs-top {
    display: flex;
    position: absolute;
    top: -41px;
    gap: 2px;
    left: -2px;

    .value-content {
        max-width: calc(522px - 28px);
    }

    &__item_active,
    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #64748B;
        padding: 10px;
        
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 40px;
        line-height: 20px;
        border-radius: var(--Base-radius, 8px) var(--Base-radius, 8px) var(--0, 0px) var(--0, 0px);
        border: 2px solid #EDEFFB;
        border-bottom: none;
        background-color: #F8F9FE;
        cursor: pointer;

        &:hover,
        &_active {
            transition: 0.5s;
            background:  #E4E5F9;
            color: #020617;
        }
    }
}