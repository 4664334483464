.telegramm-block {
    display: flex;
    width: 230px;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: #419FD9;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url('./img/bkg-tg.svg');

    &__title {
        color: #FFF;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__description {
        color: #FFF;
        font-family: "Golos Text";
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 140px;
        min-height: 32px;
        font-size: 13px;
        border-radius: 8px;
        border: none;
        color: white;
        
        font-weight: 500;
        min-width: 120px;
        padding: 4px 4px;
        height: 32px;
        color: #000;
        cursor: pointer;

        &:hover {
            transition: 0.5s;
            background-color: #419FD9;
            color: #fff;

            path {
                transition: 0.5s;
                fill: #fff;
            }
        }
    }
}