.set-remaining-goods-master-product {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    .title_main {
        color: #020617;
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 114.286% */
        letter-spacing: -0.56px;
    }

    .toolbar-top, .toolbar-medium {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        .filters-container{
            align-items: center;
            flex: 1;

            .search-component{
                max-width: 565px;
            }
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex-wrap: wrap;
    }
}