@import 'src/common/styles/svg_img.scss';

// Color variables
$colors: (
  gray-light: #F9F9F9,
  gray-text: #636D65,
  gray-border: #E2E2E2,
  gray-bg: #EFEFEF,
  gray-divider: #EBEBEB,
  black: #020617,
  white: #FFF
);

// Status colors
$status-colors: (
  green: (background: #BBF7D0, text: #15803D),
  yellow: (background: #FFEDD5, text: #FB923C),
  grey: (background: #F1F5F9, text: #64748B),
  blue: (background: #E7F1FF, text: #1890FF),
  red: (background: #FECACA, text: #EF4444)
);

// Font settings
$font-family: "Open Sans", sans-serif;
$font-size-xs: 10px;
$font-size-sm: 11px;
$font-size-md: 13px;
$line-height-sm: 12px;
$line-height-md: 16px;

// Mixins
@mixin flex-center($gap: 0) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
}

@mixin text-ellipsis($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

@mixin status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  height: auto;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: $font-family;
  font-size: $font-size-sm;
  font-weight: 400;
  line-height: $line-height-sm;
  
  p {
    @include text-ellipsis(2);
    text-align: center;
    font-size: $font-size-xs;
    line-height: $line-height-sm;
  }
}

// Component styles
.task-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid map-get($colors, gray-border);
  background: map-get($colors, white);
  padding: 8px;
  position: relative;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &__img-user {
    width: 18px;
    height: 18px;
  }

  &__top-content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__id {
    @include flex-center(6px);
    width: fit-content;
    padding: 2px 4px;
    color: map-get($colors, gray-text);
    font-family: $font-family;
    font-size: $font-size-sm;
    line-height: $line-height-md;
    border-radius: 4px;
    background: map-get($colors, gray-light);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    margin-left: auto;
    border-top: 1px solid map-get($colors, gray-divider);
    padding-top: 12px;
  }

  &__bottom-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    
    &-right {
      @include flex-center(6px);
    }
  }

  &__executors-list {
    @include flex-center(2px);
    height: 24px;
    padding: 0 4px;
    border-radius: 4px;
    background: map-get($colors, gray-bg);
  }

  &__count {
    @include flex-center;
    min-width: 17px;
    padding: 1px 2px;
    border-radius: 2px;
    background: var(--BG-Default-Primary, #51CA64);
    color: map-get($colors, white);
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: 600;
    line-height: $line-height-md;
    margin-right: auto;
  }

  &__date {
    @include flex-center(6px);
    padding: 2px 4px;
    border-radius: 4px;
    background: map-get($colors, gray-light);
    color: map-get($colors, gray-text);
    font-family: $font-family;
    font-size: $font-size-sm;
    line-height: $line-height-md;
  }

  // Common elements
  .executor-placeholder,
  .executors-list img {
    min-width: 18px;
    width: 18px;
    min-height: 18px;
    height: 18px;
    color: map-get($colors, white);
    font-family: $font-family;
    font-size: 9px;
    
    &__text {
      line-height: 1 !important;
    }
  }

  .priority {
    @include flex-center(4px);
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .text {
      color: map-get($colors, black);
      font-family: $font-family;
      font-size: $font-size-md;
      line-height: $line-height-md;
      letter-spacing: 0.07px;
      @include text-ellipsis(3);
      max-width: 160px;
    }

    // Status badges
    @each $name, $color in $status-colors {
      .info-status-color_#{$name} {
        @include status-badge;
        background: map-get($color, background);
        color: map-get($color, text);
      }
    }
  }

  // Context menu
  .context-more {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    right: 9px;
    top: 12px;
    transition: opacity 0.5s;

    .btn-more {
      @include flex-center;
      background-color: #F5F7F8;
      border: 1px solid #E0E7F2;
      border-radius: 4px;
      background-size: 20px;
      background-position: center;
    }

    .drop-menu {
      min-width: 170px;
      width: 170px;

      &_active {
        height: auto;
        opacity: 0;
        visibility: hidden;

        &__btn {
          height: 32px;
        }
      }
    }

    .delete {
      color: #F88181;
    }
  }

  &:hover .context-more {
    opacity: 1;
    visibility: visible;
  }
}