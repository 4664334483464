.selected-rating-multi {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 40px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E0E7F2;

    &__item,
    &__item_active {
        display: flex;
        gap: 4px;
        align-items: center;
        background-color: inherit;
        border: none;
        cursor: pointer;

        &:hover,
        &_active {
            transition: 0.5s;

            svg {
                transition: 0.5s;
                fill: rgb(250, 175, 0);
            }

            path {
                stroke: rgb(250, 175, 0);
            }
        }

        &:hover {
            svg {
                transform: scale(1.5);
            }
        }



    }

    &__num {
        color: #64748B;
        
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}