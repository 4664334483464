.modal-add-account-webmaster {
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    min-width: 360px;
    background-color: white;
    border-radius: 10px;
    max-height: calc(100vh - 120px);
    gap: 16px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;
    min-height: 700px;
    max-height: 700px;

    &__header {
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: space-between;

        .input-dinamyc-placeholder {
            max-width: 290px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        gap: 16px;
        background: #FFF;
        overflow: hidden;

        .input-field-wrapper {
            max-width: 100%;
        }
    }

    .btn-basic {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
    }
}