.task-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 120px);
    height: 100%;
    gap: 16px;
    border-radius: 8px;
  
    &__hover-box {
      border-radius: 10px;
      height: 100%;
      margin: 0 8px;
    }
  
    &__top-info {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    &__sum-tasks {
      display: flex;
      gap: 24px;
      margin-left: auto;
      flex-wrap: nowrap;
    }
  
    &__sum-text {
      display: flex;
      gap: 6px;
      align-items: center;
      color: #020617;
      font: 400 13px/18px "Open Sans";
  
      &_span {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #0B110B;
        font: 700 13px/14px "Open Sans";
        background: #E1E6E2;
      }
    }
  
    .btn-add-executor {
      background: #EDEFED url('./img/account.svg') no-repeat center / 15px;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer;
    }
  
    .confirm-delete-task-list {
      position: absolute;
      top: 25%;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 160px;
      width: 400px;
      border-radius: 8px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
      border: none;
  
      form {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
  
        .buttons-container {
          display: flex;
          width: 100%;
          gap: 12px;
          justify-content: space-between;
        }
      }
    }
  
    .toolbar-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      position: relative;
      z-index: 2;
  
    
  
      .members-box {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
  
        .btn-close {
          border: none;
          background-color: inherit;
          margin-left: 8px;
        }
  
        .sum_executor {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #F1F5F9;
          color: #2E3552;
          font: 800 10px/8px "Open Sans";
          cursor: pointer;
          margin-left: 8px;
  
          span {
            color: rgba(46, 53, 82, 0.6);
          }
        }
      }
  
      .right-filter-box {
        display: flex;
        gap: 12px;
        align-items: center;
  
        .input-field-wrapper {
          min-width: 210px;
          max-width: 210px;
        }
      }
  
      .left-box {
        display: flex;
        gap: 16px;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
  
        .selector_wrapper {
          max-width: 225px;
        }
  
        .checkbox-container__text {
          white-space: nowrap;
        }
      }
    }
  
    .main-task-box {
      display: flex;
      width: 100%;
      min-height: 70vh;
      overflow: auto;
      position: relative;
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
  
    &__content {
      display: flex;
      width: 100%;
      height: 97.5%;
      align-items: stretch;
  
      &.loading {
        opacity: 0.4;
        pointer-events: none;
      }
  
      .col-task {
        position: relative;
        min-width: 240px;
        max-width: 240px;
        padding-bottom: 8px;
        border-radius: 12px;
        height: calc(100% - 51px);
  
        &__count {
          display: flex;
          min-width: 17px;
          padding: 1px 2px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          background: #F1F5F9;
          color: #989898;
          font: 600 10px/16px "Open Sans";
          margin-left: auto;
        }
  
        &:first-child {
          margin-left: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
        &__title-col, &__input-col {
          width: 107px;
          color: #0B110B;
          font: 500 13px/18px "Open Sans";
          max-height: 33px;
          min-height: 33px;
          word-break: break-word;
        }
  
        &__title-col {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
  
        &__input-col {
          padding: 0;
          transition: all 0.5s;
          background-color: unset;
          border: none;
          resize: none;
          overflow: hidden;
          hyphens: auto;
  
          &::placeholder {
            color: #C5CCD3;
          }
  
          &:focus {
            outline: none;
          }
  
          &:hover {
            background-color: white;
          }
        }
  
        &__drop-icon {
          cursor: grab;
  
          &:active {
            cursor: grabbing;
          }
  
          &:hover path {
            fill: #51CA64;
          }
        }
  
        &__task-box, &__task-box-btn {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow-y: auto;
        }
  
        &__task-box-btn {
          padding-bottom: 8px;
          background-color: inherit;
          border-top: none;
        }
  
        .no-tasks {
          display: flex;
          flex-direction: column;
          gap: 6px;
          align-items: center;
          margin-top: 200px;
          cursor: pointer;
  
          img {
            width: 45px;
            height: 45px;
          }
  
          .text {
            color: #969C99;
            font: 400 11px/14px "Open Sans";
            text-align: center;
          }
        }
  
        &__header-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          width: 100%;
  
          .btn-state {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1px solid #EDEFED;
            background: #FAFBFA;
          }
        }
  
        &__white {
          background: #FFF;
          padding: 8px;
          gap: 8px;
          border-radius: 8px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          border: 1px solid #EDEFED;
          position: relative;
  
          .static-info {
            color: #636D65;
            font: 400 11px/10px "Open Sans";
            letter-spacing: -0.24px;
  
            span {
              color: #020617;
              font-weight: 700;
            }
          }
  
          .sum-task {
            color: #8F99A3;
            font: 400 15px/normal "Open Sans";
            text-align: center;
          }
        }
  
        &__header {
          display: flex;
          flex-direction: row;
          position: sticky;
          top: 0;
          width: 100%;
          z-index: 1;
  
          &:hover .title-input {
            color: #00B45E;
          }
        }
  
        .remove-task-list {
          position: absolute;
          right: -8px;
          top: 0;
          width: 24px;
          height: 24px;
          border-radius: 16px;
          border: none;
          cursor: pointer;
          background: #D9D9D9 url(./img/close_icon.svg) no-repeat center/12px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;
        }
  
        &:hover .remove-task-list {
          visibility: visible;
          opacity: 1;
        }
      }
  
      .column-drop-placeholder {
        min-width: 0;
        height: 100%;
        min-height: 70vh;
        background-color: white;
        transition: min-width 0.5s;
  
        &.hover {
          min-width: 160px;
          background-color: #E7F1FF;
        }
      }
  
      &[dragOver="true"] .column-drop-placeholder {
        min-width: 80px;
        border-radius: 16px;
        border: 2px dotted grey;
      }
  
      .col-task_admin {
        min-width: 320px;
        max-width: 320px;
      }
    }
  }