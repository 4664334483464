.summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    position: relative;

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
    }

    &__period {
        display: flex;
        width: 100%;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 10px;
        background: #FFF;
        min-height: 114px;
    }

    &__items-period {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
    }

    &__item-period {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
    }

    &__text-period {
        color: #0C2037;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    &__text-sum {
        color: #0C2037;
        text-align: right;
        font-size: 29px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__title-period {
        color: #0C2037;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__title {
        color: #0C2037;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }
}