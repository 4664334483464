.information-for-billing {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        padding: 24px 32px;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #EDEFED;
        max-width: 800px;
        background-color: #fff;
    }

    &__info-card {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__row {
        padding: 20px 0px;
        border-bottom: 1px solid #EDEFED;

        &:nth-child(1) {
            border-top: 1px solid #EDEFED;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__text {
        width: auto;
        color: var(--main-menu-primary-text, #020617);
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__placeholder {
        width: auto;
        color: #475569;
        font-size: 11px;
        min-width: 200px;
        max-width: 200px;
    }

    &__value-box {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-right: 32px;
        position: relative;
    }

    &__group {
        display: flex;
        gap: 20px;
        border-bottom: 1px solid #E0E7F2;

        .information-for-billing__row {
            width: calc(50% - 10px);
            border: none;
        }
    }

    &__title-main {
        color: var(--main-menu-primary-text, #020617);
        font-family: "Open Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__group-btn {
        display: flex;
        justify-content: end;
        gap: 20px;
        width: 100%;
        margin-top: 30px;

        .btn__white,
        .btn__blue {
            margin: 0;
            width: 100%;
            max-width: 183px;
            min-width: 183px;
        }
    }

    &__input-group {
        display: flex;
        gap: 20px;
    }
}