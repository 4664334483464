.modal-add-product {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    width: 60%;
    min-width: 360px;
    background-color: white;
    padding: 32px 24px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: calc(100vh);
    gap: 20px;
    animation: slidein 2000ms;

    @keyframes slidein {
        from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0%);
          }
        }

    .title {
        color: #020617;
        text-align: center;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E0E7F2;
    }

    .top-panel {
        display: flex;
        gap: 12px;
        align-items: center;

        .input-field-wrapper {
            max-width: 100%;
        }
    }

    .table-paginator-box {
        height: 100%;

        &__content {
            min-height: 100%;
            height: 100%;
            max-height: 100%;
        }
    }
}