.card-order,
.card-order_active {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    min-width: 160px;
    min-height: 92px;
    border-radius: 12px;
    border: 1px solid #F2F4FD;
    background: #FDFDFF;
    cursor: pointer;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 38px;
        padding: 8px 12px;

        .count-box {
            display: flex;
            padding: 0px 4px;
            align-items: center;
            gap: 2px;
            height: 20px;
            border-radius: 4px;
            background: #E3EAF4;
            overflow: hidden;
            color: #020617;
            text-align: right;
            text-overflow: ellipsis;
            font-family: "Open Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .text_date {
            overflow: hidden;
            color: #020617;
            text-overflow: ellipsis;
            
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }

        .date-content {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .text_grey {
                overflow: hidden;
                color: #64748B;
                text-overflow: ellipsis;
                
                font-size: 9px;
                font-style: normal;
                font-weight: 700;
                line-height: 10px;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 2px;
        padding: 8px 12px;

        .text {
            color: #64748B;
            
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;

            span {
                color: #020617;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
            }
        }
    }

    &:hover,
    &_active {
        transition: 0.5s;
        border: 1px solid rgba(255, 255, 255, 0.02);
        background: #EBEDEB;
    }

    &_active {
        transition: 0.5s;
        background: #EBEDEB;
        border: 1px solid #0B110B;

        .card-order__header {
            .text_date {
                font-weight: 500;
            }
        }
    }
}