// @import url('../../../font.scss');

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    max-height: 600px;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;

    &__box {
        width: 100%;
        margin-bottom: 32px;
    }

    .img-container__button__readonly,
    .img-container__button {
        display: flex;
        border: none;
        width: 100%;
        background-color: inherit;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;

        .img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 600px;
            object-fit: contain;
        }

        .hidden-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: white;
            opacity: 0.5;
            transition: 0.3s;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;

            .text {
                color: #2B2B46;
                font-family: "Golos Text";
                font-size: 32px;
                line-height: 1.1;
            }
        }

        &:hover {
            .hidden-placeholder {
                visibility: visible;
                opacity: 0.5;
            }

        }


    }


    .blackout {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        z-index: 10;
        position: fixed;
        overflow: auto;
        align-items: center;
        justify-content: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 20px;
    }

    .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &__placeholder {
        width: 200px;
        height: 200px;
    }

    &__button {
        min-width: 100%;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        border: none;
        border-radius: 16px;
        background-color: unset;
        min-height: 100px;

        &__placeholder {
            display: flex;
            width: 400px;
            height: 400px;
            justify-content: center;
            align-items: center;
        }
    }

    &__file-input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    &__img-placeholder {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 1100px) {
    .img-container {
        &__box {
            margin-bottom: 24px;
        }
    }
}

@media (max-width: 430px) {
    .img-container {
        &__box {
            margin-bottom: 18px;
        }
    }
}