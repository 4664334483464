.referral-program {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    position: relative;

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_label,
            .text_label_disabled {
                color: #020617;
                
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4;

                &_disabled {
                    color: #D3DEE7;
                }
            }
        }

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__title-medium {
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4
    }

    &__balance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        min-width: 334px;
        height: 54px;
        border: 2px #F2F2F4 solid;
        background: #FFF;
        border-radius: 10px;
        padding: 8px;
    }

    &__balance-field {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 6px 14px;
        border-radius: 10px;
        background: #F6F8FF;
        min-width: 180px;
    }

    &__text-label {
        white-space: nowrap;
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__text-blue {
        color: #365EFF;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4;
        white-space: nowrap;
    }

    &__fields {
        display: flex;
        gap: 12px;
    }

    &__header {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
    }

    &__period {
        display: flex;
        width: 100%;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 10px;
        background: #FFF;
    }

    &__items-period {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
    }

    &__item-period {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    &__text-period {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    &__text-sum {
        color: #0C2037;
        text-align: right;
        font-family: "Golos Text";
        font-size: 29px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;

        span {
            font-family: 'Golos text' !important;
            font-weight: 400;
        }
    }

    &__title-period {
        color: #0C2037;
        font-family: "Golos Text";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4;
    }

    &__table {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    &__content-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }

    &__table-and-filters {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background: #FFF;
        padding: 16px;
        gap: 12px;
    }

    &__title {
        color: #0C2037;
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__title_period {
        color: #0C2037;
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 12px;
    }

    &__text {
        color: #000;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
    }

    &__cards {
        display: flex;
        gap: 12px;
    }

    &__card {
        display: inline-flex;
        padding: 8px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-radius: 4px;
        background: #FFF;
        min-height: 76px;
        min-width: 200px;
    }

    &__card-text {
        color: #0C2037;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4
    }

}

.copy-text-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 10px;
    border: 2px solid #F2F2F4;
    background: #FFF;
    padding: 18px;
    width: 50%;

    .btn-basic {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        color: #0C2037;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4;
    }

    &__field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        border-radius: 10px;
        background: #F6F8FF;
        padding: 7px 12px;
    }

    &__text {
        color: #365EFF;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4;
        word-break: break-word;
    }

    &__btn {
        display: flex;
        width: 42px;
        height: 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #00B45E;
        border: none;
        cursor: pointer;
    }

    &__content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 4px;
    }
}