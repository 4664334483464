.modal-product-improvement {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 12px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  max-width: 500px;
  width: 100%;
  min-width: 360px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  flex-direction: column;
  background: var(--white, #fff);
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  &__parametrs-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    width: 40%;
  }

  &__parametrs-right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 60%;
    height: 100%;
  }

  .tabs {
    margin-bottom: 12px;
  }

  .title {
    color: #020617;
    font-family: NunitoSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 8px;
    border: 2px solid #edefed;
    height: auto;
    overflow: auto;

    .parameter-item {
      display: flex;
      gap: 16px;
      padding: 16px 20px;
      cursor: pointer;

      &:hover {
        transition: 0.5s;
        border-radius: 8px;
        background: #f1f2fd;
      }

      &__content {
        display: flex;
        flex-direction: column;

        .title {
          color: #222;
          
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }

        .text {
          color: #2e3552;
          
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
