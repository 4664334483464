.modal-mass-editing {
    max-width: 960px;
    width: 100%;
    height: 100%;
    max-height: 800px;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background: #FFF;

    &__header {
        display: flex;
        gap: 32px;
        width: 100%;
        padding: 16px 32px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);
        background: #F8F9FE;

        .title_main {
            color: #020617;
            
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: calc(100% - 66px);
        padding: 32px;
        overflow: auto;

        .toolbar {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .text {
                color: #000;
                
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .right-content {
                display: flex;
                gap: 12px;
                width: auto;

                .btn {
                    display: flex;
                    height: 32px;
                    padding: 4px 12px;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 8px;
                    background: #F8F9FE;
                    border-radius: 8px;
                    border: 2px solid #EDEFFB;
                    overflow: hidden;
                    color: #555C9E;
                    text-overflow: ellipsis;
                    
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                }
            }
        }

        .textarea-dynamic-placeholder {
            height: 100%;
            max-height: calc(100% - 56px - 24px - 32px - 12px);

            &__content {
                height: 100%;
            }
        }
    }
}