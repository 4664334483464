.set-remaining-goods {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 24px;

  .title_main {
    color: #020617;
    
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 114.286% */
    letter-spacing: -0.56px;
  }

    .toolbar-top, .toolbar-medium {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;

        .filters-container {
            flex: 1;

            .search-component {
                max-width: 600px;
            }
        }
    }

  .content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
  }
}
