.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 12px 0px 12px;

    // Заголовок
    &__content {
        max-width: 1440px;
        padding-left: 60px;
        padding-right: 60px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__address {
        display: flex;
        flex-direction: column;
        gap: 1em;
        max-width: 160px;
    }

    // вверхняя часть одвала
    &__upper-box {
        display: flex;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        height: 100%;
        justify-content: center;
        border-bottom: 1px solid #C5CCD3;
        border-top: 1px solid #C5CCD3;
    }

    // нижняя часть подвала
    &__bottom-box {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
    }

    &__link {
        display: inline-block;
        color: var(--slate-500, var(--slate-500, #64748B));
        font-family: "Open Sans";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 0.4em;
        column-gap: 2.3em;
        margin: 0;
        padding: 0;
    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 30px;
        justify-content: space-between;
        padding-left: 28px;
        padding-right: 28px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        max-width: 160px;
        width: 100%;
        height: auto;
    }

    &__copyright {
        color: #70808F;
        display: inline-block;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        white-space: normal;
    }

    .footer__org-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-left: 28px;
        padding-right: 28px;
        width: 100%;
        max-width: 316px;
        border-left: 1px solid #C5CCD3;
        border-right: 1px solid #C5CCD3;

        .text {
            color: #020617;
            font-family: "Open Sans";
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        .footer_org-inner {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 12px;

            .img-box {
                width: calc(50% - 6px);
            }

            img {
                width: fit-content;
                height: 16px;
            }
        }
    }
}

.country-box {
    width: 200px;
}

.icon_square {
    display: block;
    margin: auto;
}