.modal-add-connection-data {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: calc(800px + 660px);
    width: 100%;
    min-width: 30px;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    max-height: calc(100vh - 120px);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    .left-content,
    .right-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;


        &__header {
            display: flex;
            width: 100%;
            gap: 12px;
            padding: 32px 56px;
            align-items: center;
            border-bottom: 2px solid #EDEFFB;
            justify-content: space-between;

            .title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #020617;
                
                font-size: 26px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: -0.56px;

                span {
                    color: #0C2037;
                    text-align: right;
                    font-family: "Golos Text";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 24px 48px;
            height: 100%;

            .selector_wrapper {
                max-width: 100%;
                min-height: 56px;
                margin-bottom: 24px;

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    min-height: 54px;
                }
            }
        }

        &__inputs {
            display: flex;
            flex-direction: column;
            gap: 28px;
            padding-top: 8px;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &__bottom-content {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 32px 56px;
        }
    }

    .right-content {
        display: flex;
        flex-direction: column;
        background: #F8F9FE;
        width: 100%;
        min-width: 660px;
        max-width: 800px;
        height: 100%;

        &__header {
            padding: 36px 56px;
        }

        &__content {
            gap: 32px;

            .step-item {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 16px;

                &__title {
                    color: #020617;
                    
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    .link_violet {
                        color: #3900B1;
                    }
                }
            }
        }
    }

    .left-content__content {
        overflow: hidden;
    }

    .btn-close,
    .btn-back {
        background-image: url('./img/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        background-color: inherit;
        position: absolute;
        left: 1em;
    }

    .btn-back {
        background-image: url('./img/back.svg');
        width: 24px;
        height: 24px;
    }



    .btn__blue {
        margin-top: 24px;
        max-width: 560px;
    }
}



.add_connection_box_wrapper {
    gap: 0;

    .additional_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn_additional {
            margin-top: unset;
        }
    }
}

.acc_auth_block {
    margin: 1em auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5em;
}

.modal_selector {
    padding: 0;

    &>div {
        width: 100%;
    }
}


.acc_auth_input_block {
    padding: .75em .5em;
    border-radius: .5em;
    border: .5px solid grey;
}

.add_connection_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.add_connectio_btn {
    padding: .5em 1.5em;
    border-radius: .5em;
    border: .5px solid grey;
    font-size: 15px;
    margin: .5em 0 0;
    cursor: pointer;
}

.add_connectio_btn:hover {
    background-color: rgba(0, 0, 0, .05);
}

.add_connectio_btn:active {
    background-color: rgba(0, 0, 0, .1);
}

.error-text {
    color: red;
    font-size: 13px;
    margin-top: 10px;
}