.analysis-by-sellers {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    overflow: hidden;

    .content-1320 {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    .chart-and-metrics-box {
        display: flex;
        flex-direction: column;
        gap: var(--12, 12px);
    }

    .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-left: auto;
        flex-wrap: wrap;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_label,
            .text_label_disabled {
                color: #020617;
                
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                &_disabled {
                    color: #D3DEE7;
                }
            }
        }
        
        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .title {
        color: var(--slate-950, #020617);
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;

        .range-group-btn-and-range-box {
            display: flex;
            align-items: center;
            gap: 12px;

            .selector_wrapper {
                max-width: 160px;
                min-width: 160px;
            }

            .drop-box {
                display: flex;
                align-items: center;
                gap: 16px;

                .selector_wrapper {
                    * {
                        text-align: center;
                    }
                }

                .css-1dimb5e-singleValue {
                    text-align: center;
                }
            }
        }
    }

    .tooltip-top {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        .search-component{
            margin-top: 0;
        }

        .range-group-btn-and-range-box {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-left: auto;

        .selector_wrapper {
            max-width: 124px;
            min-width: 124px;

            .dropdown_selector {
                border: 1px solid #C5CCD3;
            }
        }

        .drop-box {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    
    }

    }

    .charts-and-metrics,
    .charts-and-metrics_hide {

        .line_chart {
            margin-top: 28px;
        }
    }

    .search-component {

        .input-box-ver2 {
            max-width: 100%;
        }

        .search-input-ver2 {
            max-width: 100%;
        }
    }

    .product-analysis {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        flex-direction: column;
        border-radius: var(--base-radius, 8px);
        background: #FFF;

        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            .btn {
                height: 41px;
                white-space: nowrap;
                padding: var(--space-x-3, 12px) var(--space-x-2, 8px);
                border-radius: 8px;
                border: 1px solid #E0E7F2;
                background: #FFF;
            }
        }

        .group-btn {
            display: flex;
            gap: 8px;
            align-items: center;

            button {
                display: flex;
                white-space: nowrap;
                padding: var(--space-x-2, 8px);
                border-radius: var(--base-radius, 8px);
                border: 1px solid var(--stroke-primary-primary, #E0E7F2);
                color: var(--main-menu-secondary-text, #475569);
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: white;
            }
        }
    }

    .metrics-items {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .review-table {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: var(--12, 12px);
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: var(--bg-seondary, #F5F7F8);

        .title {
            color: var(--main-menu-secondary-text, #475569);
            /* Desktop 2.0/Heading/Heading 6 */
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .position-in-category,
    .similar-products {
        display: flex;
        padding: var(--space-x-6, 24px);
        flex-direction: column;
        gap: var(--12, 12px);
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: var(--bg-seondary, #F5F7F8);
    }

    .table-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 40px;

        .table-and-textarea {
            display: flex;
            gap: 10px;
            width: 100%;

            .table-box-component {
                width: calc(50% - 5px);
                max-width: calc(50% - 5px);
            }

            .textarea-group-component {
                width: calc(50% - 5px);
                margin-top: 44px;
            }
        }
    }

    .sum-requests-container {
        width: fit-content;

        .sum-requests {
            display: flex;
            height: 32px;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: var(--white, #FFF);

            .text_grey-blue {
                color: var(--text-light-grey, #89AAC7);
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;

                span {
                    color: var(--text-primary-blue, #020617);
                    font-family: "Open Sans";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
            }
        }
    }

    .metrics-card-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 260px;
        padding: 12px 15px 12px;
        background-color: #ffffff;
        border: 2px solid #F7F7FD;
        border-radius: 8px;
        position: relative;

        &__header {
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: var(--main-menu-secondary-text, #475569);
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px
            }

            .text_grey {
                color: #AAAAAA;
            }
        }

        &__content {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .text_grey-blue {
                color: var(--text-light-grey, #89AAC7);
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;

                span {
                    color: var(--main-menu-secondary-text, #475569);
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

        }
    }
}