@import 'src/common/styles/svg_img.scss';

.modal-add-task {
    display: flex;
    position: absolute;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    min-width: 360px;
    border-radius: 0px 0px 0px 8px;
    justify-content: space-between;
    width: 100%;
    max-width: 1460px;
    max-height: 700px;
    // overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;

    &__options-task {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .button-copy {
        position: relative;
        display: inline-flex;
        align-items: center;
    }

    .favorites-message {
        width: 40px;
        height: 100%;
        min-width: 40px;

        &__content {
            margin-top: 58px;
            width: 100%;
            background-color: #fff;
            border-radius: 8px 0px 0px 8px;
            height: 300px;
            padding: 9px;
        }

        &__btn,  &__btn_active {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            border-radius: 4px;
            background: #F5F5F5;
            border: none;
            cursor: pointer;

            &:hover, &_active  {
                border: 1px solid var(--Text-Accent-Green, #00B45E);
            }
        }
    }

    &__list-fields {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 10px;
    }

    &__fixed-message {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        border-bottom: 1px solid var(--Stroke-Primary, #EDEFED);
        background-color: #fff;
        z-index: 1;
        padding-right: 12px;
        max-width: 100%;
        cursor: pointer;
    }

    &__fixed-message-content {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;

        .comment-item {
            background-color: #FFF;
            overflow: hidden;

            .text {
                max-height: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    &__btn-close {
        border: none;
        background-color: inherit;
        cursor: pointer;
    }

    &__save-indicator {
        display: flex;
        padding: 6px 8px;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        border-radius: 4px;
        background: #F9F9F9;
        align-items: center;
        gap: 6px;
        color: #636D65;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        margin-left: auto;
        min-width: 180px;
    }

    .button-action-drop {
        margin-left: auto;
    }

    .selector_wrapper {
        max-width: 180px;
        min-width: 180px;
    }


    // Подзадачи

    .subtasks-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 10px;

        &__btn {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        &__btn-delete,
        &__btn-edit {
            display: flex;
            border: none;
            background-color: inherit;
            gap: 8px;
            align-items: center;

            img {
                width: 16px;
                height: 16px;
            }
        }

        &__input-box {
            display: flex;
            gap: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
        }

        &__item {
            display: flex;
            gap: 8px;
        }

        &__text,
        &__text-through {
            width: 100%;
            color: #0B110B;
            font-family: "Golos Text";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.1;
        }

        &__input {
            width: 100%;
            color: #0B110B;
            font-family: "Golos Text";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.1;
            border: none;

            &:focus {
                outline: none;
            }
        }

        &__text-through {
            text-decoration: line-through;
        }

        &__date {
            white-space: nowrap;
            width: 130px;
            color: #767676;
            text-align: center;
            text-overflow: ellipsis;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.1;
        }
    }

    // Стили чата

    &__task-id {
        display: flex;
        padding: 6px 8px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #F9F9F9;
        gap: 6px;
        color: #636D65;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        gap: 6px;
        cursor: pointer;
    }

    &__no-data-file {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 20px;
        align-items: center;
        justify-content: center;

        img {
            width: 80px;
            height: auto;
        }
    }

    &__no-data-file-description {
        color: #000;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        max-width: 300px;
    }

    &__no-data-file-text {
        color: #000;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-top: 14px;
    }

    &__chat {
        display: flex;
        flex-direction: column;
        width: 470px;
        min-width: 470px;
        background-color: white;
        border-right: 1px solid #EDEFED;

        .comment {
            margin-top: auto;
        }
    }

    &__no-data-message {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__comment-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: calc(100% - 58px);
        position: relative;
        border-left: 1px solid #EDEFED;
        //  overflow: hidden;
    }

    &__list-message, &__list {
        padding: 12px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        // transform: scaleY(-1);
        // counter-reset: i 0;

        .comment {
            background: inherit;
            flex-direction: row;
            padding: 0;
            // counter-increment: i;
        }

        .redactor-wrapper {
            max-width: 257px;
        }
    }

    &__list-message {
        display: flex;
        flex-direction: column-reverse;
    }

    &__date-message {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 10px;
        color: #979797;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__title-comment {
        color: #111827;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    // Контент в правой части

    &__right-content {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        min-width: 360px;
        background-color: white;
        max-width: 1100px;
        width: 100%;
    }

    // Нижняя часть

    &__bottom-content {
        display: flex;
        min-height: 270px;
        height: 270px;
        padding: 12px;
        gap: 12px;
    }

    &__content-with-drop-zone {
        display: flex;
        overflow: auto;
        width: 100%;
    }

    &__tab-and-content {
        display: flex;
        width: calc(100% - 24px);
        flex-direction: column;
        padding: 12px;
        gap: 10px;
        border-radius: 8px;
        background: #FAFCFF;
    }

    &__link-list {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        width: 100%;
        height: 100%;
        gap: 8px;
        overflow: auto;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 36px;
        gap: 8px;
        align-items: center;
        color: #767676;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        cursor: pointer;

        &:hover {
            .modal-add-task__link-btn {
                display: flex;
            }

            .modal-add-task__link-info {
                display: none;
            }
        }
    }

    &__link-file {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #475569;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        width: calc(100% - 220px);

        span {

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    &__link-btn {
        display: none;
        gap: 4px;
        padding: 6px;
        border-radius: 4px;
        background: #E7EDF5;
    }

    &__link-info {
        display: flex;

        span {
            display: flex;
            padding: 0px 8px;

            &:nth-child(1) {
                border-right: 1px solid #767676;
            }
        }
    }

    .sceleton-header {
        display: flex;

        &__content {
            display: flex;
            flex-direction: row;
            gap: 24px;

            span {
                border-radius: 4px;
            }

            span:nth-child(1) {
                border-radius: 50%;
            }
        }
    }

    .sceleton-select-group {
        display: flex;

        &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            span {
                border-radius: 4px;
            }
        }

        &__user {
            display: flex;
            gap: 6px;

            span {
                border-radius: 4px;
            }

            span:nth-child(1) {
                border-radius: 50%;
            }
        }
    }

    .sceleton-file {
        display: flex;

        &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            span {
                border-radius: 4px;
            }
        }

        &__item {
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: space-between;
        }

    }

    .sceleton-main-info {
        display: flex;
        width: 100%;

        &__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;

            span {
                border-radius: 4px;
            }
        }

        &__item {
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: space-between;
        }

        &__comment {
            display: flex;
            flex-direction: column;
            gap: 22px;
        }
    }

    .sceleton-chat {
        display: flex;
        width: 100%;

        &__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;

            span {
                border-radius: 4px;
            }

            .round {
                border-radius: 50%;
            }
        }

        &__message {
            display: flex;
            gap: 8px;

            &:nth-child(odd) {
                margin-left: auto;
            }
        }

        &__item {
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: space-between;
        }

        &__comment {
            display: flex;
            flex-direction: column;
            gap: 22px;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    .history-actions,
    .history-actions_hide {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 12px;
        border-radius: 8px;
        max-height: 100%;

        width: 100%;
        min-width: 260px;
        max-width: 260px;
        padding: 12px;
        transition: 0.5s;
        background: #FAFCFF;

        &__header {
            display: flex;
            min-width: calc(236px);
            align-items: center;
            justify-content: space-between;

            .btn-arrow {
                border: none;
                background-color: inherit;
                cursor: pointer;
            }
        }

        &__title {
            color: #636D65;
            text-align: right;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 14px;
            overflow: auto;
            min-width: calc(236px);
            height: 100%;

            &::-webkit-scrollbar {
                width: 0;
            }

            .history-item {
                display: flex;
                justify-content: space-between;
                gap: 40px;
                width: 100%;

                &__info {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    position: relative;
                }

                &__text-label {
                    color: #0B110B;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                }

                &__text-value,
                &__text-date {
                    color: #6B7280;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    word-break: break-word;
                }

                &__text-date {
                    white-space: nowrap;
                }
            }
        }
    }



    .executor {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .executor-content {
        display: flex;
        gap: 8px;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;

        .text_name {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .title-content-editable {
        background-color: #fff;
        border: 1px solid #E7E7E7;

        &_edit {
            border: 1px solid #E7E7E7;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        justify-content: space-between;
        gap: 24px;
        padding: 10px 12px;
        width: 100%;
        // min-height: 58px;
        border-bottom: 1px solid #EDEFED;

        &:nth-child(2) {
            gap: 16px;
            justify-content: start;
        }

        .executor-placeholder {
            min-width: 42px;
            width: 42px;
            height: 42px;
        }

        .select-group {
            display: flex;
            gap: 16px;
            border-radius: 8px;



            .date-box-single {
                min-width: 210px;
                max-width: 210px;
                height: 40px;
            }

            .checkbox-content {
                display: flex;
                align-items: center;
                gap: 8px;

                .text {
                    color: #0B110B;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    white-space: nowrap;
                }
            }
        }

        .title_task {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;

            span {
                color: #636D65;
                font-family: NunitoSans;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
            }
        }

        .group-btn {
            display: flex;
            gap: 22px;
            align-items: center;
        }

        .btn-delete,
        .btn-info,
        .btn-copy-link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border: none;
            border-radius: 8px;
            background: #FEF2F2;
        }

        .btn-info {
            background: #DFE0FF;
        }

        .btn-copy-link {
            background-color: #E1EAE1;
        }
    }

    &__content {
        display: flex;
        gap: 12px;
        position: relative;
        min-height: calc(360px + 24px);
        height: 100%;

        .comment-container {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            width: 100%;
            gap: 20px;

            .comment-item__content {
                a {
                    color: #0009E8;
                }
            }
        }
    }

    &__header-left-box {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    &__btn-group {
        display: flex;
        padding: 12px;
        width: 100%;
        justify-content: end;
    }

    .context-more {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 5;

        .btn-more {
            width: 24px;
            min-width: 24px;
            height: 24px;
            background-image: url('./img/more-vertical.svg');
            border: none;
            background-color: inherit;
        }

        .drop-menu,
        .drop-menu_active {
            position: absolute;
            width: 124px;
            height: 32px;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            border: 1px solid #E0E7F2;
            background: #FFF;
            top: calc(100% + 10px);
            right: 0px;
            box-shadow: 6px 8px 8px 0px rgba(30, 41, 59, 0.09);
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;

            &_active {
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
            }

            &__btn {
                padding-left: 8px;
                padding-right: 8px;
                width: 100%;
                height: 100%;
                background-color: inherit;
                border: none;
                text-align: left;
                color: var(--text-primary-black, #1E293B);
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &:hover {
                    transition: 0.3s;
                    background-color: #F7FAFD;
                }
            }
        }
    }

    //option status
    .option-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;

        .status_red {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FECACA;
            border: 1px solid #EF4444;
        }

        .status_yellow {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #FFEDD5;
            border: 1px solid #FDBA74;
        }

        .status_green {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #BBF7D0;
            border: 1px solid #15803D;
        }
    }

    .main-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 12px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .additional-information {
        display: flex;
        height: 100%;
        width: auto;
        position: relative;

        .select_executor__select_content {
            position: static;

            .select_executor__drop_content {
                top: 0;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            min-width: calc(210px);
            max-width: calc(210px);

            .select_executor__input {
                background-color: inherit;
            }

            .btn-more-task {
                display: flex;
                align-items: center;
                gap: 4px;
                border: none;
                cursor: pointer;
                background-color: initial;
                color: #636D65;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-left: auto;
                transition: .3;
                padding-right: 8px;
            }

            .info-group {
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: 100%;
                margin-top: 20px;
                overflow-x: hidden;
                overflow-y: auto;
                // overflow-y: clip;
            }
        }



        .top-panel {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: space-between;
            padding-right: 36px;
            padding-bottom: 10px;
            border-bottom: 1px solid #E0E7F2;

            .tooltip-box {
                position: relative;

                .tooltip_black {
                    opacity: 0;
                    visibility: hidden;
                    background-color: #4A4A4A;
                    border-radius: 2px;
                    overflow: hidden;
                    color: white;
                    position: absolute;
                    font-size: 11px;
                    top: calc(100% + 4px);
                    right: 0;
                    z-index: 100;
                    width: max-content;
                    height: auto;
                    padding: 2px 4px;
                    border: 1px solid #E0E7F2;
                }

                &:hover {
                    .tooltip_black {
                        opacity: 1;
                        visibility: visible;
                        transition: 0.5s;
                    }
                }
            }

            .btn-link {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 4px;
                border: 1px solid #E0E7F2;
                background: #F5F7F8;
                background-image: url('./img/link_icon.svg');
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .text_date {
            color: #8F99A3;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .select-group {
            display: flex;
            flex-direction: column;
            gap: 14px;
            width: 100%;
            height: 100%;
            //  overflow-x: hidden;
            //  overflow-y: hidden;
            padding: 12px;
            border-left: 1px solid #EDEFED;


            &__date-box {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 8px;
            }

            .selector_wrapper {
                max-width: 100%;
            }

            .date-box-single {
                min-width: 100%;
                max-width: 100%;
                height: 40px;
            }

            .checkbox-content {
                display: flex;
                align-items: center;
                gap: 8px;

                .text {
                    color: #0B110B;
                    font-family: NunitoSans;
                    font-size: 11px;
                    line-height: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }

        .autor-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            justify-content: space-between;

            .text {
                color: #636D65;
                text-align: right;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }





            .executor-placeholder {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
            }
        }


    }
}

.search_add-executor_active {
    cursor: pointer;
    color: #020617;
    outline: none;
}

.search-box-executor {
    display: flex;
    position: relative;
    width: 100%;
    height: 48px;

    .search_add-executor,
    .search_add-executor_active {
        position: relative;
        width: 100%;
        height: 48px;
        border-radius: 22px;
        padding: 10px;
        transition-duration: 1000ms;
        cursor: pointer;
        background-repeat: no-repeat;
        font-size: 11px;
        color: transparent;
        //border: 1px solid #F0F0F0;
        border: none;
        overflow: hidden;
        background-image: url('./img/executor_icon.svg');
        background-color: white;
        background-size: 48px;
        background-position: 0% 50%;
        padding-left: 56px;

        &::placeholder {
            color: #0009E8;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        &:focus {
            outline: none;
        }
    }

    .options-box,
    .options-box_active {
        position: absolute;
        left: 0;
        top: calc(100% + 4px);
        display: flex;
        width: 100%;
        flex-direction: column;
        border-radius: 16px;
        background-color: white;
        position: absolute;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        right: -24px;
        cursor: pointer;
        transition: 1s;
        visibility: hidden;
        opacity: 0;
        transition: all 1s;
        overflow: hidden;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .options-box_active {
        z-index: 1;
        visibility: visible;
        opacity: 1;
        transition: all 1s;
    }
}

.text_grey {
    font-size: 11px;
    color: #888888;
}

.text-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 11px;
}

.tooltip-executors-box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .close-executor {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -15px;
        top: -4px;
        border-radius: 16px;
        border: none;
        cursor: pointer;
        background-image: url($close);
        background-color: #F5F5F5;
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: center;
        opacity: 0;
        visibility: hidden;
    }

    img {
        position: absolute;
        top: 0;
        right: -10px;
    }

    .tooltip-executors {

        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 34px;
        border-radius: 16px;
        padding: 8px 16px;
        background-color: white;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }

    .text-box {
        display: flex;
        flex-direction: column;

        .text {
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
        }

        .text_grey {
            font-size: 10px;
            color: grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 180px;
        }
    }


}

.tooltip-executors-box:hover {
    .tooltip-executors {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 34px;
        border-radius: 16px;
        padding: 8px 16px;
        background-color: white;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.5s;
    }

    .close-executor {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -15px;
        top: -4px;
        border-radius: 16px;
        border: none;
        cursor: pointer;
        background-image: url($close);
        background-color: #D9D9D9;
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: center;
        opacity: 1;
        visibility: visible;
        transition: all 0.5s;
    }
}

.modal-add-task {


    .flag_red {
        stroke: #FA0F0F !important;

        path {
            stroke: #FA0F0F !important;
        }
    }

    .flag_green {
        stroke: #27BC62 !important;

        path {
            stroke: #27BC62 !important;
        }
    }

    .flag_yellow {
        stroke: #FA9C0F !important;

        path {
            stroke: #FA9C0F !important;
        }
    }

    .flag_blue {
        stroke: #1890FF !important;

        path {
            stroke: #1890FF !important;
        }
    }
}