.installation-DRR {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;

    &__toolbar-top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 12px;


        .filters-container {
            flex: 1;
        }
    }
}