.card-statistics-ver2 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 20px;
    min-width: 158px;
    width: auto;
    border-left: 2px solid #F8F9FE;

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        color: #64748B;
        
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        min-height: 28px;
    }

    .value-and-dynamic {
        display: flex;
        align-items: center;
        gap: 40px;

        .dynamic_violet,
        .dynamic_red {
            display: flex;
            padding: 2px 8px;
            align-items: center;
            gap: 4px;
            border-radius: 128px;
            background: #E4E5F9;
            color: #3900B1;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .dynamic_red {
            background-color: #FEF2F2;
            color: #EF4444;
        }
    }

    &__value-content {
        color: #020617;
        font-family: "Open Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;

        span {
            color: #94A3B8;
        }
    }

}