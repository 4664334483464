.account-management {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    
    &__content {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 32px;
        max-width: 800px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #F2F2F4;
        background-color: #fff;

        &:has(.account-notifications) {
            max-width: 100%;
         }

        .group-btn {
            display: flex;
            gap: 20px;
            width: 100%;
            justify-content: end;

            .btn__white {
                max-width: 93px;
                min-width: 93px;
            }
        }
    }

}