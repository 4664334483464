.modal-general-order-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 750px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 120px);
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        padding: 4px 20px;
        width: 100%;
        border-radius: 4px 4px 12px 12px;
        border: 4px solid #FFF;
        background: var(--info-bg, #E7F1FF);

        .title {
            color: #404E8C;
            font-family: "Open Sans";
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }

    .title_medium {
        color: #020617;
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .general-data {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            width: 100%;

            .status_green, .status_yellow, .status_blue {
                display: flex;
                padding: 4px 24px;
                white-space: nowrap;
                border-radius: 12px;
                background: #EBF6EB;
                color: #31AA27;
                text-align: center;
                font-family: PT-Root;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
            }

            .status_blue {
                color: #0161B9;
                background-color: #EDEDFE;
            }

            .status_yellow {
                color: #E99518;
                background-color: #FFF4E4;
            }
        }

        &__content {
            display: flex;
            width: 100%;
        }

        .characteristics-group {
            display: flex;
            width: 100%;
            gap: 30px;

            .characteristics-items {
                max-width: 330px;
            }
        }

        .characteristics-items {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;

            .button-copy {
                position: relative;
                top: -6px;

                .tooltip_black {
                    top: 12px;
                    right: 0;
                }
            }

            .characteristics-item {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-end;

                .border {
                    width: 100%;
                    border-bottom: 1px solid #E0E7F2;
                }

                .text,
                .text_grey {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    color: #475569;
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    max-width: 48%;
                    width: -webkit-fill-available;

                    &_grey {
                        color: #94A3B8;
                        text-align: left;
                    }
                }

                .text {
                    min-width: 40%;
                }
            }
        }
    }

    &__content {
        display: grid;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        padding: 24px;
        padding-bottom: 0;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .table-box-component__content {
            max-height: 500px;
            min-height: 500px;
        }
    }

    .btn-group {
        display: flex;
        padding: 24px;
        width: 100%;
        justify-content: end;
        gap: 12px;

        .content-btn {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 8px;
            align-items: center;
        }
    }

}