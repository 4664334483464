.summary-matrices {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;

    &__toolbar {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
        justify-content: space-between;

        .filters-container {
            flex: 1;
        }
    }

    &__btn-table {
        display: flex;
        gap: 4px;
        height: 20px;
        padding: 0px 5px;
        align-items: center;
        gap: 1px;
        border-radius: 2px;
        background: #E1E1E1;
        color: #020617;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        border: none;
    }
}