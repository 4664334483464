.accept-mail {
    display: flex;
    flex-direction: column;
    max-width: 472px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: var(--base-radius, 8px);
    background: #FFF;
    padding: 48px 32px;
    margin-top: 5%;

    .title {
        padding: 0;
        margin-top: 24px;
        font-size: 18px;
        font-weight: 500;
    }

    .text_grey-blue {
        text-align: center;
        border-radius: 8px;
        font-size: 13px;
        margin-top: 12px;
    }

    .link__blue {
        color: var(--unnamed, #1890FF);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 32px;
    }

    .btn__blue {
        min-width: 120px;
        max-width: 300px;
        min-height: 52px;
        margin-top: 24px;
    }
}