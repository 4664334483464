.cookie {
    position: fixed;
    z-index: 10000;
    right: 32px;
    bottom: 32px;
    display: flex;
    min-width: 432px;
    width: 432px;
    padding: 24px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    background: #FEFEFE;
    box-shadow: 0px 20px 50px 0px rgba(24, 144, 255, 0.10);

    img {
        width: 44px;
        height: 44px;
    }

    .text {
        color: var(--text-primary-blue, #020617);
        text-align: right;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .btn__blue {
        min-height: 56px;
        max-width: 60px;
        min-width: 60px;
    }
}