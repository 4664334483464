.modal-edit-file {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
}