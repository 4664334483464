@import 'src/common/styles/mixins.scss';
@import 'src/common/styles/svg_img.scss';

.row-week__item_day {
    scroll-margin-top: 45px;
}

.row-week__header {
    scroll-margin-top: 80px;
}

.time-box {
    scroll-margin-top: 115px;  
}

.calendar-page {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    gap: 24px;

    .hour-text {
        color: #94A3B8;
        text-align: center;
        
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }



    .number-box_day {
        background-color: #fff !important;
    }

    .btn-group {
        display: flex;
        align-items: center;
        border: 1px solid #EDEFFB;
        background-color: #EDEFFB;
        border-radius: 8px;
        overflow: hidden;
        gap: 2px;

        .btn-range,
        .btn-range_active {
            border: none;
            padding: 4px;
            background: #F8F9FE;
            min-width: 40px;
            height: 40px;
            max-height: 40px;
            white-space: nowrap;
            color: var(--Slate-500, #64748B);
            
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;

            span {
                color: #020617;
                
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            // &:nth-child(1) {
            //     border-radius: 8px 0 0 8px;
            // }

            // &:last-child {
            //     border-radius: 0 8px 8px 0;
            // }

            &:hover,
            &_active {
                transition: 0.5s;
                color: white;
                background-color: #3900B1;

                span {
                    color: white;
                }
            }

            &:disabled {
                border: 2px solid #F6F7FD;
                background: #F8F9FE;
                color: #D3DEE7;
            }
        }
    }

    .time-now-line {
        display: flex;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #E81919;
        z-index: 1;

        &:before {
            content: '';
            position: relative;
            bottom: 4px;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #E81919;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -4px;
            right: 0;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #E81919;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;

        .toolbar-top {
            position: sticky;
            top: 0;
            z-index: 6;
            background: #F6F7F6;
            display: flex;
            align-items: center;
            column-gap: 40px;
            row-gap: 16px;
            flex-wrap: wrap;
            min-height: 85px;
            justify-content: space-between;

            .left-content,
            .right-content {
                display: flex;
                align-items: center;
                column-gap: 20px;
                row-gap: 16px;
            }

            .calendar-tab-box {
                min-width: 150px;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                height: 40px;
                padding: 12px 16px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #EDEFED;
                background: #FAFBFA;

                .text {
                    color: #020617;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.12px;
                    text-align: center;
                }

                img {
                    margin-left: 8px;
                }
            }

            .light-blue-box {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 120px;
                padding-left: 16px;
                padding-right: 16px;
                background-color: #E6F7FF;
                height: 32px;
                border-radius: 16px;
                cursor: pointer;
            }

            .date-picker-custom {
                position: absolute;
                top: 50px;
                left: 0px;

                font-weight: 400;
                font-size: 15px;
                min-width: 136px;
                text-align: center;
                box-shadow: 0 0 4px #000000;
                z-index: 1000;
            }
        }
    }

    .side-menu-calendar {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        display: flex;
        flex-direction: column;

        .select-box-and-btn {
            width: 200px;
            height: 32px;
            position: relative;
            margin-top: 16px;

            .btn__add-green {
                position: absolute;
                top: calc(50% - 11px);
                right: 0;
            }
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            padding: 0;
            min-height: 32px;
            vertical-align: middle;
        }

        .react-datepicker {
            border: none;
            margin-top: 16px;
            max-width: 200px;

            &__header {
                background-color: white;
                border: none;
            }

            &__day-names {
                display: none;
            }

            &__day {
                margin: 0;
            }

            &__day-names {
                margin: 0;
            }
        }
    }
}

.btn__prev,
.btn__next {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    border: none;
    background-color: #C2CAE6;
    border-radius: 50%;
    @include optimizeSafariSvg(url('./img/arrow_icon.svg'));
}

.btn-toolbar-box {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    align-items: center;
}

.calendar-selector {
    min-width: 92px;
    max-width: 120px;
}

.calendar-btn-back {
    position: relative;
    min-width: 295px;
    height: 40px;
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    transition: background-color ease-in .25s;

    &:before {
        content: '';
        vertical-align: middle;
        background-image: url('https://dl.ecomru.ru/svg-storage/arrow-grey.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 14px;
        transform: rotate(180deg);
        margin-right: 6px;
    }

    &:hover {
        background-color: #C5CCD3;
    }

    .calendar-btn-back__text {
        padding-left: 6px;
    }
}

.btn__next {
    transform: rotate(180deg);
}

.select-box-icon {
    position: relative;

    img {
        position: absolute;
        top: 25%;
        left: 16px;
        z-index: 1;
    }
}

.btn__add-green {
    cursor: pointer;
    width: 64px;
    height: 64px;
    background-color: #00B45E;
    background-image: url('./img/add_icon.svg');
    border-radius: 8px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 100%;

    &_day {
        padding-bottom: 20px;
    }

    .week_text {
        color: #B4B8B4;
        text-align: center;
        font-family: "Open Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }

    .month_text {
        color: #B4B8B4;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }

    .number-box {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F8F9FA;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        transition: background-color ease-in .25s;
        cursor: pointer;

        &_day {
            width: 46px;
            height: 46px;
        }

        .text_number {
            color: #8F99A3;
            text-align: center;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }
}

.calendar-grid {
    width: 100%;
    overflow: hidden;

    thead {
        //  background-color: white;
        position: relative;
        z-index: 5;
    }

    &__header {
        display: flex;
        width: 100%;
        height: 120px;
        padding-left: 80px;

        &_item:first-child {
            max-width: 64px;
            min-width: 64px;
        }

        &_item,
        &_item-active {
            height: 69px;
            border: 1px solid #EDEFFB;
            position: relative;
            background-color: #fff;
            z-index: 10;
            border-left: none;
            border-right: none;


            &:nth-child(1) {
                border-left: 1px solid #EDEFFB;
                // background-color: #fff;
            }

            &:last-child {
                border: none;
                border-right: 1px solid #EDEFFB;
                // background-color: #fff;
            }

            &_month {
                height: 42px;

                &:nth-child(1) {
                    border: none;
                }

            }


        }

        &_item-active {
            background-color: #F7F7FD;

            .date-box {
                background-color: #F6F7F6;

                .week_text {
                    color: #00B45E;
                }

                .number-box {
                    background-color: #F6F7F6;

                    &:hover {
                        background-color: #F7F7FD;
                    }

                    .text_number {
                        color: #00B45E;
                    }
                }
            }
        }
    }

    .table-week {
        width: 100%;
        border-collapse: collapse;


        &:last-child {
            border-bottom: none;
        }

        .row-week {
            width: 100%;
            height: 64px;
            position: relative;

            &__header {
                width: 100%;
                height: 30px;
                padding: 8px;
                background-color: #EDEFFB;
                text-align: center;
                color: #B4B8B4;
                text-align: center;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
            }

            .time-box {
                max-width: 64px;
                min-width: 64px;
                height: 38px;
                text-align: center;
                position: relative;
                color: #94A3B8;
                text-align: center;
                
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                background-color: #fff;
                border: 1px solid #EDEFFB;

                .count-week {
                    max-width: 64px;
                    min-width: 64px;
                    white-space: nowrap;
                    color: #BEC2E7;
                    text-align: center;
                    
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    transform: rotate(-90deg);
                }
            }

            &__item {
                width: calc(1 / 7) * 96%;
                height: 38px;
                border: 1px solid #EDEFFB;
                cursor: cell;
                position: relative;
                background: #fff;

                &_month {
                    font-size: 13px;
                    color: #8F99A3;
                    vertical-align: top;
                    text-align: left;
                    text-align: start;
                    height: 170px;
                    background-color: #fff;
                }

                &_day {
                    width: 97%;
                    height: 68px;
                    background-color: #fff;
                }
            }

        }
    }

}



.dynamic-search-bar {
    position: relative;
    overflow: hidden;

    .search {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        padding: 10px;
        transition-duration: 1000ms;
        cursor: pointer;
        background-repeat: no-repeat;
        font-size: 13px;
        color: transparent;
        border: 1px solid #EDEFFB;
        overflow: hidden;
        background-image: url('./CalendarToolbarTop/img/search_icon.svg');
        background-color: white;
        background-size: 16px;
        background-position: right 12px center;
        padding-left: 40px;
        transition: 0s;
        color: #020617;
        
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding-right: 0px;

        &:focus {
            transition: 1s;
            width: 300px;
            cursor: pointer;
            padding-left: 16px;
            color: #3B2C37;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            outline: none;
            border: 1px solid #555C9E;
            padding-right: 60px;
        }

        &::placeholder {
            color: #94A3B8;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
        }
    }

    .btn-close {
        border: none;
        background-color: inherit;
        position: absolute;
        top: 12px;
        right: 44px;
    }
}