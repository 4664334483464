.modal-promotion-rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  max-width: 1220px;
  width: 100%;
  min-width: 622px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 40px);
  height: 100%;
  gap: 24px;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  padding: 20px 24px;

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 60px;

    .input-container {
      width: 100%;
      max-width: 242px;
    }
  }

  &__title {
    white-space: nowrap;
    color: #020617;
    font-family: 'Nunito Sans';
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }

  &__header-left,
  &__header-right {
    display: flex;
    gap: 20px;

    .submitBtn {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .loader {
      width: 24px;
      height: 24px;
    }
  }

  &__header-left {
    flex: 1;
  }

  &__step-info {
    width: 100%;
    align-items: center;
    color: #0a30ce;
    display: flex;
    font-family: NunitoSans;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    gap: 16px;
    line-height: 28px;
  }

  &__step-description {
    color: #000;
    font-family: NunitoSans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    span {
      color: red;
    }
  }

  &__toogle-checkbox {
    display: flex;
    align-items: center;
    width: auto;
    gap: 12px;
  }

  &__text_label {
    color: #636d65;
    
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__text_status {
    color: #475569;
    font-family: NunitoSans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__toolbar-top,
  &__toolbar-medium {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;

    .selector-container {
      max-width: 100%;
      width: 100%;

      .selector_wrapper {
        max-width: 100%;
      }
    }
  }

  &__toolbar-top {
    .selector-container {
      max-width: 400px;
      width: 100%;

      .selector_wrapper {
        max-width: 100%;
      }
    }
  }

  &__toolbar-medium {
    border-top: none;
  }

  &__btn-close {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  .promotion-rules-parametr {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: var(--8, 8px);
    background: #fff;
    box-shadow: 0px 3px 20px 0px rgba(0, 17, 45, 0.2);
    z-index: 10;
    align-items: center;
    max-width: calc(418px + 56px * 2);
    top: 164px;

    &__header {
      width: 100%;
      border-bottom: 2px solid #edeffb;
      padding: 20px 32px;

      .title_main {
        color: var(--slate-950, #020617);
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 20px 32px;

      .title {
        color: #020617;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .input-box {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 622px;
    height: 100%;
    max-height: calc(100% - 20px);
    gap: 16px;
    width: 100%;
    padding: 20px 56px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .selector_wrapper {
      margin-top: 8px;
    }
  }

  .toolbar {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn-add {
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-image: url('./img/user_plus.svg');
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      border: 1px solid #e0e7f2;
      border-radius: 50%;
      margin-left: 8px;
      cursor: pointer;
    }

    .text {
      color: #8f99a3;
      font-family: "Open Sans";
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .icon-group {
      display: flex;
      align-items: center;

      .img {
        margin-left: -6px;
        width: 24px;
        height: 24px;

        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }

  .rules-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: auto;
    gap: 20px;

    .input-box {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    &__tooltip {
      display: flex;
      width: 200px;
      padding: 16px;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      background: #eff6ff;
      color: #636d65;
      
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      .checkbox-status input[type='checkbox']:checked + .checkbox-status-switch,
      .checkbox-status_disabled
        input[type='checkbox']:checked
        + .checkbox-status-switch {
        border: 2px solid #cb84ec;
        background: #cb84ec;
      }
    }

    .content-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 8px;
      align-items: center;
    }

    &__content {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-left: auto;
      margin-right: auto;
      min-width: calc(500px + 12px * 2 + 400px);

     
    }

    .column-group {
      display: flex;
      gap: 16px;
    }

    &__side-panel {
      display: flex;
      flex-direction: column;
      min-width: 224px;
      max-width: 224px;
      gap: 24px;
    }
  }
}

 .col-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #edefed;
        background: #fafbfa;
        max-width: 240px;
        width: 240px;
        position: relative;

        &__header {
          display: flex;
          width: 100%;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-bottom: 2px solid #edefed;
          border-radius: 8px 8px 0px 0px;
          background: #fff;
          min-height: 76px;

          .title {
            color: #64748b;
            text-align: center;
            font-family: "Open Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;

            span {
              color: #020617;
            }
          }

          .info-icon {
            cursor: pointer;
          }
        }

        &__btn_settings {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #edefed;
          background: #fafbfa;

          &:hover {
            border: 1px solid #bbf7d0;
          }

          &:active {
            border: 1px solid #00b45e;
          }
        }

        &__list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 60vh;
          min-height: 60vh;
          padding: 8px;

          &::-webkit-scrollbar {
            width: 0;
          }

          .no-list {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: center;
            width: 100%;
            height: fit-content;
            margin-top: 200px;
            justify-content: center;

            img {
              width: 45px;
              height: 45px;
            }

            .text {
              color: #94a3b8;
              text-align: center;
              font-family: "Open Sans";
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }

          &[loading='true'] {
            pointer-events: none;
            opacity: 0.3;
          }
        }

        &__card {
          display: flex;
          width: auto;
          padding: 12px;
          flex-direction: column;
          justify-content: space-between;
          gap: 12px;
          border-radius: 12px;
          border: 2px solid #edefed;
          background: #ffffff;
          cursor: grab;
          min-height: 106px;

          .text {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
            color: #020617;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            min-height: 48px;
          }

          .status-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .status {
              display: flex;
              min-height: 18px;
              height: 18px;
              padding: 4px 8px;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: #f1f5f9;
              color: var(--slate-400, #94a3b8);
              
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
            }

            .card-disactive {
              @extend .status;
              background: #f1f5f9;
              color: #94a3b8;
            }

            .card-active {
              @extend .status;
              background-color: #86efac;
              color: #15803d;
            }
          }
        }

        .green-btn {
          align-self: flex-end;
        }
      }