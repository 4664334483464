.title-page {
    color: #020617;
    font-family: 'Open Sans';
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;

    &_medium {
        @extend .title-page;
        font-size: 18px;
    }
}