.send-message_container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    width: 100%;
    height: auto;
    gap: 12px;
    border-top: 1px solid #EDEFED;
}

.field-and-changed-message {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .changed-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 8px;
        padding-right: 8px;
        height: 24px;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 0px 4px 4px 0px;
        border-left: 2px solid #9298CF;
        background: #F9F8FC;
        color: #9298CF;
        
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
    }

    .btn-close {
        width: 16px;
        height: 16px;
        background-image: url('./img/close_icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        background-position: center;
        position: static;
    }
}

.send-message {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    position: relative;

    &__btn-group {
        display: flex;
        gap: 16px;
        width: auto;
        height: 40px;
        align-items: center;
        justify-content: center;

        .btn-smile,
        .btn-attach {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            min-width: 24px;
            height: 24px;
            cursor: pointer;
            background-color: inherit;
            border: none;
            position: relative;
        }

        .input-file {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    textarea {
        border-radius: 8px;
      //  box-shadow: 0px 2px 12px 0px rgba(24, 144, 255, 0.04);
        background: var(--day-carbone-10, #FFF);
        width: calc(100% - 28px);
        margin-left: 14px;
        margin-right: 14px;
        padding: 12px 20px;
        padding-right: 60px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        resize: none;
        vertical-align: middle;
        color: #020617;
        
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        height: 100%;
        max-height: 200px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        &::placeholder {
            color: var(--text-tertiary, #A1A7B3);
            /* Основной текст/Подписи */
            
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }


}