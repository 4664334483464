.enhance-video-inside-content {
    display: flex;
    gap: 24px;
    width: 100%;

    .content-item {
        display: flex;
        width: 100%;
        padding: 16px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 8px;
        border: 2px solid #EDEFED;
        background: #FFF;

        .drop-box {
            position: relative;
            width: 100%;

            input {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
            }
        }

        .content-photo-btn {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 16px;
            align-items: center;
        }

        .text_sum-photo {
            color: #475569;
            
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            span {
                @extend .text_sum-photo;
                color: #8F99A3;
            }
        }

        .video-group {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .video-box,
            .video-box-big {
                display: flex;
                // width: 84px;
                // height: 88px;
                overflow: hidden;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 12px;
                border-radius: 8px;
                border: 2px solid #EDEFED;
                background: white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .video-box-big {
            width: 100%;
            aspect-ratio: 4/3;
            height: auto;
            background-color: #A4A4A4;
            border-radius: 10px;
        }
    }
}