.modal-feedback-2 {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    width: auto;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    height: fit-content;
    border-radius: 8px;

    .b24-form-wrapper.b24-form-shadow {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .btn-close {
        cursor: pointer;
        position: absolute;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: inherit;
        top: 10px;
        right: 10px;
    }

    .b24-form-inner-box {
        background-color: white;
    }

    .b24-form-sign {
        padding-left: 0;
        padding-right: 0;
        background-color: white;
    }

    .b24-form-header,
    .b24-form-padding-side {
        form {
            margin-top: 24px;

            div {
                display: flex;
                flex-direction: column;
            }
        }

        border: none;
    }

    .b24-form-header-title {
        color: var(--, #464853);
        text-align: center;
        font-family: "Open Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        /* 48px */
        letter-spacing: 0.15px;
        margin-bottom: 0;
    }

    .b24-form-control {
        height: 60px;
    }

    .b24-form-content,
    .b24-form-padding-side {
        padding: 0;
    }

    .b24-form-btn-block {
        width: 100%;
    }

    .b24-form-btn {
        border-radius: var(--Main-System-10px, 10px);
        background: #00B45E;
        width: 100%;
        height: 60px;
        color: var(--, #FFF);
        text-align: center;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        /* 32px */
        letter-spacing: 0.1px;

        &:hover {
            background: #15803D;
            transition: 0.5s;
        }
    }

    .b24-form-header-description {
        color: var(--, #464853);
        text-align: center;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        /* 32px */
        letter-spacing: 0.1px;
        margin-bottom: 0;
    }

    .b24-form-field,
    .b24-form-field-name,
    .b24-form-control-string,
    .b24-form-control-alert {
        .b24-form-control-label {
            position: absolute;
            top: -10px;
        }
    }

    .b24-form-control-alert {
        margin-bottom: 15 !important;
    }

    .b24-form-field {
        margin-bottom: 24px;
        -webkit-transition: 200ms linear margin-bottom;
        -o-transition: 200ms linear margin-bottom;
        transition: 200ms linear margin-bottom;
    }

    .b24-form-control-container,
    .b24-form-control-icon-after {
        width: 100%;
        border-radius: 10px;
        background: #F4F5F9;
        border: none;
        color: var(--, #464853);
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.1px;
        resize: none;
        border: none;

        .b24-form-control-label {
            position: absolute;
            top: -20px;
            color: #8E91A1;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            transform: none;
            flex-direction: row;

            .b24-form-control-required {
                position: relative;
                left: .1em;
            }
        }

        .b24-form-icon-after,
        .b24-form-icon-remove {
            background-image: url('./img/close-icon.svg');
            background-repeat: no-repeat;
            background-position: center;

            &::after,
            &::before {
                display: none;
            }
        }




        .b24-form-control:focus+.b24-form-control-label {
            position: absolute;
            top: -20px;
            color: #8E91A1;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            transform: none;
        }

        .b24-form-control,
        .b24-form-control-not-empty {
            width: 100%;
            padding: 0;
            border-radius: var(--Main-System-10px, 10px);
            background: #F4F5F9;
            border: none;
            color: var(--, #464853);
            font-family: "Open Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            padding-left: 20px;
            padding-right: 20px;
            letter-spacing: 0.1px;
            border: none;
            border-radius: 10px;

            // &:-webkit-autofill {
            //     background: #F4F5F9 !important;
            // }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0px 1000px #F4F5F9 inset;
                transition: background-color 5000s ease-in-out 0s;
                border-radius: 10px !important;
            }

            &:focus {
                outline: none;


            }
        }
    }

    .b24-form-content,
    .b24-form-padding-side {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 22px;

        .input-box {
            display: flex;
            flex-direction: column;

            .text_name-field {
                color: #8E91A1;
                font-family: "Open Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
                letter-spacing: 0.1px;
            }



            textarea {
                width: 100%;
                height: 120px;
                border-radius: 10px;
                background: #F4F5F9;
                border: none;
                color: var(--, #464853);
                font-family: "Open Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
                padding-left: 20px;
                padding-right: 20px;
                letter-spacing: 0.1px;
                resize: none;
                padding-top: 16px;
                padding-bottom: 16px;

                &:focus {
                    outline: none;
                }
            }
        }

        .text_info {
            max-width: 300px;
            text-align: center;
            margin-top: 28px;
            color: #696C7B;
            font-family: "Open Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0.075px;
        }
    }
}

body {
    .blackout {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        z-index: 10;
        position: fixed;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 20px;
    }
}

@media (max-width: 420px) {
    .modal-feedback {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px);
        position: absolute;
        top: 12px;
        padding: 24px 16px;
        min-width: 340px;

        .btn-close {
            width: 24px;
            height: 24px;
            top: 10px;
            right: 10px;
        }

        .title {
            max-width: 80%;
            font-size: 24px;
            line-height: 32px;
        }

        .text {
            font-size: 16px;
            line-height: 20px;
        }

        .input-group {
            width: 100%;

            .input-box {
                .text_name-field {
                    font-size: 16px;
                }

                input {
                    width: 100%;
                    height: 50px;
                }
            }

            .text_info {
                margin-top: 8px;
            }
        }
    }

    .b24-form-btn-block {

        // .b24-form-btn {
        //     background-color: #4285F4;

        // }
    }
}

.blackout {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 10;
    position: fixed;
    overflow: auto;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.3);
    padding: 20px;
}

.form-consultation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 20px 20px;
    border-radius: 12px;
    border: 1px solid #DDD;
    margin: 0;


    .text_blue {
        color: #00B45E;
        text-align: center;
        font-family: "Golos Text";
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.6;
    }

    .text_grey {
        text-align: center;
        color: #434C5B;
        text-align: center;
        font-family: "Golos Text";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        line-height: 1.6;
    }

    .btn-basic {
        font-size: 24px;
    }
}

@media (max-width: 1100px) {
    .form-consultation {
        margin: 18px 0;

        .text_blue {
            font-size: 22px;
        }

        .btn-basic {
            font-size: 20px;
        }
    }
}

@media (max-width: 430px) {
    .form-consultation {
        margin: 18px 0;

        .btn-basic {
            font-size: 16px;
        }
    }
}