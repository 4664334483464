@import '../../common/styles/mixins.scss';
.main-menu {
    z-index: 8 !important;
}
.main-sidebar {
  z-index: 9 ;
  display: block;
  width: auto;
  padding: 0 20px;
  background-color: #fff;
  gap: 16px;

  // Tooltip and notification styles
.tooltip-container {
  width: 36px;
  height: 36px;
  position: relative;

  .tooltip_light-blue {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 11;
    top: 36px;
    left: 15px;
    padding: 6px;
    border-radius: 2px;
    background: #E7F1FF;
    color: #005DB2;
    font: 500 11px/16px Inter, sans-serif;
    transition: 0.5s;
  }

  &:hover .tooltip_light-blue {
    opacity: 1;
    visibility: visible;
  }

  .notification-count {
    position: absolute;
    top: -4px;
    left: 22px;
    border-radius: 8px;
    background-color: #48A8FE;
    display: flex;
    min-width: 16px;
    padding: 0 4px;
    height: 16px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    font-size: 11px;
  }
}


  &__nav {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    flex-wrap: wrap;
  }

  &__space-content {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  &__btn-info {
    border: none;
    background-color: inherit;
    cursor: pointer;
  }

  &__burger-btn {
    display: none;
    width: 36px;
    height: 36px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    background: #FFF;
    cursor: pointer;

    @media (max-width: 1100px) {
      display: flex;
    }
  }

  &__right-box {
    display: flex;
    gap: 20px;
    align-items: center;
    min-height: 60px;
    margin-left: auto;
  }

  &__group-btn {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .logo-and-search {
    display: flex;
    gap: 16px;
    align-items: center;
    width: auto;
    min-height: 60px;
    margin-right: 24px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


// Button styles
.notifications__btn,
.message__btn,
.upload__btn {
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 1px solid #EDEFED;
  background: #FFF no-repeat center;
  background-size: 28px;
  border-radius: 8px;
  cursor: pointer;

  &:hover  {
    border: 1px solid #51CA64;
  }
}

.notifications__btn { background-image: url('./img/question.svg'); }
.upload__btn { background-image: url('./img/load.svg'); }
.message__btn { background-image: url('./img/bell.svg'); }

// Profile styles
.profile-box {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;
  position: relative;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    color: #FFF;
    background: #F79E6C;
    font: 400 11px/12px NunitoSans, sans-serif;
    border: none;
  }

  &__user-name {
    color: #020617;
    font: 400 11px/1 Rubik, sans-serif;
    letter-spacing: 0.24px;
  }
}

.balance-box-num {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  border-left: 1px solid #D8DDF5;
  border-right: 1px solid #D8DDF5;
  white-space: nowrap;
  color: #0B110B;
  font: 400 14px/18px NunitoSans, sans-serif;
  height: 100%;

  span {
    color: #A1D9A3;
    font: 400 15px/18px Rubik, sans-serif;
  }
}

.option-space {
  display: flex;
  align-items: center;
  gap: 8px;
}