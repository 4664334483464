.hint-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  &__title {
    color: #020617;
    
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.56px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }

  &__card-content {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
}

.card-recommendation {
  display: flex;
  width: 324px;
  padding: 12px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  border-radius: 10px;
  background: #fff;

  &__header {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  &__info-panel {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__title {
    overflow: hidden;
    color: #0b110b;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: NunitoSans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__status-read {
    display: flex;
    height: 18px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    text-align: center;
    font-family: NunitoSans;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .read {
    background: #51ca64;
    color: #f8f9fe;
  }

  .no-read {
    background: #f0f2f4;
    color: #0b110b;
  }

  &__status-criticality {
    display: flex;
    padding: 2px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #f0f2f4;
    font-family: NunitoSans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  .status-green {
    color: #45a153;

    svg {
      path {
        fill: #45a153;
      }
    }
  }

  .status-red {
    color: #e56868;

    svg {
      path {
        fill: #e56868;
      }
    }
  }

  .status-orange {
    color: #f3a34e;

    svg {
      path {
        fill: #f3a34e;
      }
    }
  }

  &__reason,
  &__solution {
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 10px;
    background: #f9fafb;
    width: 100%;
  }

  &__title-reason,
  &__title-solution {
    color: #f3a34e;
    font-family: NunitoSans;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  &__title-solution {
    color: #45a153;
  }

  &__text {
    color: #0b110b;
    font-family: NunitoSans;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}

.breadcrumbs {
  margin-bottom: 20px;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0 5px;

      .breadcrumb-divider {
        // margin: 0 5px;
        color: #7b7b7b;
        font-size: 18px;
      }

      .breadcrumb-icon {
        margin-right: 5px;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: black;
        font-weight: 600;
        transition: color 0.2s;

        &:hover {
          color: #0537eb;
        }

        &.active {
          color: #0537eb;
          pointer-events: none;
        }
      }
    }
  }
}
