.modal-crm-attr {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 100vh;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__btn-close {
    background-color: inherit;
    border: none;
    width: 16px;
    height: 16px;
  }

  &__title {
    color: #020617;
    
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.56px;
  }

  &__title_min {
    color: #000;
    
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  &__main-content {
    display: flex;
    order: 1;
    flex-direction: column;
    gap: 24px;
    width: 50%;
    min-width: 800px;
    background: white;
    padding: 40px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
    height: 100%;
    // overflow-y: auto;
    animation: slideIn 0.5s ease-out;
    z-index: 10;
  }

  &__generated-modal {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    background: white;
    animation: slideIn 0.5s ease-out;
    padding: 24px;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    max-height: calc(100% - 160px);
  }

  &__new-el-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header-new {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
  }

  &__title-new {
    color: #000;
    
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 50%;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-y: auto;

    &:nth-child(1) {
      padding-right: 40px;
      border-right: 1px solid #d9d9d9;
    }

    &:nth-child(2) {
      padding-left: 40px;
    }
  }

  &__btn-edit {
    border: none;
    background: none;
    cursor: pointer;
  }

  &__label {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__btn-group {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: auto;
  }

  &__input-item {
    display: flex;
    gap: 12px;
  }

  &__checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 12px;
  }

  &__medium-panel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  &__btn-sort {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #3900b1;
    
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    background-color: inherit;
    border: none;
    cursor: pointer;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
