.product-card-extended {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    gap: 24px;
    position: relative;

    .title_main {
        color: #020617;
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
    }

    &__card-info {
        display: flex;
        min-width: 270px;
        max-width: 270px;
        flex-direction: column;
        gap: 22px;
        position: sticky;
        top: 20px;

        .cards-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .img-info {
            display: flex;
            flex-direction: column;
            padding: 14px;
            background-color: #fff;
            border-radius: 8px;
            width: 100%;
            justify-content: space-between;
            gap: 18px;

            .img-box {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                overflow: hidden;
                width: 100%;
                min-width: 100%;
                min-height: 240px;
                max-height: 240px;
                position: relative;
                background-color: white;
                border-radius: 8px;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .review-sticker {
                    display: flex;
                    align-items: center;
                    padding-left: 8px;
                    display: flex;
                    width: 107px;
                    height: 70px;
                    gap: 6px;
                    position: absolute;
                    right: 5px;
                    bottom: 4px;
                    border-radius: 6px 6px 0px 6px;
                    background: #F3F6FB;
                    z-index: 1;

                    .text_rating {
                        overflow: hidden;
                        color: #020617;
                        text-overflow: ellipsis;
                        
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;

                        span {
                            color: rgba(2, 6, 23, 0.50);
                            font-size: 15px;
                        }
                    }
                }

                .not-img {}

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .info-content {
            display: flex;
            flex-direction: column;
            gap: 18px;
            width: 100%;
            justify-content: space-between;

            &__label {
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .text-placeholder {
                overflow: hidden;
                color: #636D65;
                text-overflow: ellipsis;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 17.744px;
                /* 126.742% */
                letter-spacing: -0.28px;
            }

            .info_container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;
            }

            &__item,
            .article-container,
            .review-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 2px;
                width: calc(100% - 24px - 24px)
            }

            .text-box {
                display: flex;
                flex-direction: column;
                gap: 6px;
                max-width: 100%;


                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;

                    .text,
                    .text_grey {
                        font-size: 15px;
                        line-height: 16px;
                    }
                }

                .text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-family: NunitoSans;
                    color: #0B110B;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px;
                    max-width: 100%;
                    overflow: hidden;
                }
            }

            .article-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                gap: 16px;

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;


                    .text_grey {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #B4B8B4;
                        font-family: NunitoSans;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .text {
                        color: #0B110B;
                        font-family: NunitoSans;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                    }
                }

                .btn-copy {
                    background-size: 24px 24px;
                }

                .btn-copy,
                .button-copy {
                    position: static;
                    width: 24px;
                    height: 24px;
                }

                .button-copy {
                    position: relative;
                }
            }

            .review-container {
                border-radius: 2px;
                background: #F3F6FB;
                width: fit-content;
                padding: 2px 4px;

                .text {
                    overflow: hidden;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 11px;
                    letter-spacing: -0.22px;

                    span {
                        font-weight: 700;
                    }
                }

                .text_rating {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    overflow: hidden;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 12px;

                    span {
                        color: rgba(2, 6, 23, 0.50);
                    }
                }
            }
        }
    }

    &__main-content {
        display: flex;
        gap: 24px;
    }

    &__toolbar-top {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        justify-content: space-between;

        .range-group-btn-and-range-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 24px;
            margin-left: auto;

            .checkbox-container {
                display: flex;
                gap: 8px;
                align-items: center;

                .text_label,
                .text_label_disabled {
                    color: #020617;
                    
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;

                    &_disabled {
                        color: #D3DEE7;
                    }
                }
            }

            .selector_wrapper {
                max-width: 124px;
                min-width: 124px;
            }

            .drop-box {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }

    .checkbox-group-filters {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        .text {
            color: #747F90;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        &__checkbox-group {
            display: flex;
            gap: 16px;
            align-items: center;

            .checkbox-container {
                gap: 6px;
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .title-and-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;

        .title_main {
            color: var(--slate-950, #020617);
            
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
        }





        .toolbar-top {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            flex-wrap: wrap;


        }





        .header-panel {
            display: flex;
            align-items: center;
            background: #FFF;
            flex-wrap: wrap;
            border-radius: 8px;
            padding: 16px;
            gap: 8px;
            width: calc(100% - 180px);

            .card-dynamic {
                max-width: unset;
                width: fit-content;
            }



            .card-product {
                display: flex;
                top: 20px;
                min-width: 360px;
                height: fit-content;
                width: 100%;
                gap: 20px;
                border-radius: 8px;





            }
        }

        .card-dynamic {
            width: 265px;
        }
    }

    .changed-content {
        display: flex;
        gap: 16px;

        .left-content {
            display: flex;
            width: 100%;
        }
    }

    .position-content {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-direction: column;
    }

    .lost_revenue-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    .card-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .card-item {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 16px 20px;
            min-width: 158px;
            max-width: 158px;
            border-left: 2px solid #F8F9FE;

            &__title {
                display: -webkit-box;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
                color: #64748B;
                
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                min-height: 28px;
            }

            &__value-content {
                color: #020617;
                font-family: "Open Sans";
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;

                span {
                    color: #94A3B8;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;


    }

    &__right-content {
        width: 100%;
        max-width: calc(100% - 270px - 24px);
    }

    &__side-panel {
        display: flex;
        flex-direction: column;
        gap: 24px;
        min-width: 311px;
        width: 311px;
        height: 100%;

        .side-panel-content {
            position: sticky;
            top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: white;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid var(--stroke-primary-primary-primary, #E0E7F2);
        }

        .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 240px;
            max-width: 240px;
            background-color: inherit;
            overflow: hidden;
            border-radius: 8px;
            transition: 1.5s;
            margin-left: auto;
            margin-right: auto;
            min-width: auto;
            aspect-ratio: 0.8/1;

            img {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .no-data_img {
                max-width: 24px;
                min-width: 24px;
                max-height: 24px;
            }
        }
    }

    .product-info {
        display: flex;
        height: auto;
        width: 100%;
        flex-direction: column;
        gap: 12px;
        border-radius: var(--base-radius, 8px);

        .range-toolbar {
            margin-left: auto;
        }

        &__header {
            display: flex;
            gap: 16px;

            .rating-and-title {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 4px;

                .title {
                    color: var(--main-menu-primary-text, #020617);
                    /* Desktop 2.0/Heading/Heading 5 */
                    font-family: "Open Sans";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                }

                .rating {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    p {
                        color: #8F99A3;
                        font-family: "Open Sans";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        span {
                            color: #0F172A;
                            font-family: "Open Sans";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
            }

            .btn-group {
                display: flex;
                gap: 8px;
                max-height: 56px;

                .btn__white {
                    min-width: 200px;
                    max-width: 200px;
                    border: 1px solid var(--button-primary-default, #1890FF);
                    color: #1890FF;

                    &:active {
                        background-color: white;
                    }
                }

                .like {
                    max-width: 56px;
                    min-width: 56px;
                    background-image: url('./img/favorites_default.svg');
                    background-position: center;
                    background-repeat: no-repeat;

                    &:hover {
                        background-color: #005DB2;
                        background-image: url('./img/favorites.svg');
                        transition: 0.3s;
                    }

                    &:active,
                    &_active {
                        background-color: #1890FF;
                        background-image: url('./img/favorites.svg');
                        transition: 0.3s;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            border-radius: var(--base-radius, 8px);
            border: 1px solid var(--stroke-primary-primary, #E0E7F2);
            padding: 16px;
            background: #FFF;


        }
    }

    .product-description {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        margin-top: 40px;

        &__header {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

        &__content {
            width: 100%;
            padding: 24px;
            border-radius: 8px;
            border: 1px solid #E7E6E9;
            background: #FFF;

            .text_description {
                overflow: hidden;
                color: #70808F;
                font-family: "Open Sans";
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

    }

    .product-analysis {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 24px;
        flex-direction: column;
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: #FFF;
        margin-top: 40px;

        .range-toolbar {
            margin-left: auto;
        }
    }

    .keywords {
        display: flex;
        width: 100%;
        padding: 28px 16px;
        flex-direction: column;
        gap: 16px;
        border-radius: var(--base-radius, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: #FFF;
        color: var(--main-menu-secondary-text, #475569);
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 40px;

        &__content {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .word {
                display: flex;
                width: auto;
                height: 32px;
                padding: 4px 20px;
                white-space: nowrap;
                align-items: center;
                border-radius: 24px;
                border: 1px solid var(--primary-primary-500, #1890FF);
                color: #4CA9FF;
            }

            a {
                display: flex;
                align-items: center;
                height: 32px;
                color: var(--main-menu-teritary-text, #94A3B8);
                /* Table/Body2_12_16 */
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

        }
    }

    .review-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: var(--12, 12px);

        .slider-review {
            display: flex;
            min-height: 48px;
            border-radius: 8px;
            border: 1px solid #E0E7F2;
            background: #FFF;
            padding-left: 24px;
            padding-right: 14px;
            align-items: center;
            width: 70%;
            gap: 12px;

            .rating {
                display: flex;
                align-items: center;
                gap: 12px;

                .rating_info {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    white-space: nowrap;
                    color: #475569;
                    
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;

                    .grey-box {
                        display: inline-flex;
                        height: 28px;
                        padding: 4px;
                        align-items: center;
                        gap: 8px;
                        border-radius: 4px;
                        background: #F1F5F9;
                        color: #020617;
                        font-family: "Open Sans";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 12px;
                    }
                }
            }

            .text {
                color: #475569;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .slider-panel {
                margin-top: 0;
            }
        }
    }

    .similar-products {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }

    .vr {
        display: flex;
        flex-direction: column;
        gap: var(--12, 12px);
        margin-top: 40px;

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__metrics-items {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
        }
    }

    .pie-charts {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;

        .title {
            color: var(--main-menu-secondary-text, #475569);
            /* Desktop 2.0/Heading/Heading 6 */
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 140% */
        }

        &__container {
            display: flex;
            width: calc(100%);
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
        }

        .toolbar-medium {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: center;
        }
    }
}