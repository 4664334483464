.modal-add-role {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1200px;
    gap: 24px;
    width: 100%;
    min-width: 360px;
    height: 100%;
    max-height: 900px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 20px;
    background: var(--white, #FFF);
    padding: 24px;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__toogle-checkbox {
        display: flex;
        align-items: center;
        width: auto;
        gap: 12px;
    }

    &__text_status {
        min-width: 90px;
        color: #475569;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        .input-dinamyc-placeholder {
            max-width: 242px;
        }

        .btn-basic {
            margin-left: auto;
        }
    }

    &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #020617;
        color: #020617;
        font-family: "Open Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    &__id {
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        color: #636d65;
        cursor: pointer;
        display: flex;
        font-family: Golos Text;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        gap: 6px;
        height: 30px;
        justify-content: center;
        line-height: 18px;
        padding: 6px 8px;
        white-space: nowrap;
    }

    &__columns {
        display: flex;
        width: 100%;
        overflow-y: auto;
    }

    .column-group {
        display: flex;
        width: 100%;
        gap: 16px;
        padding-left: 56px;
        padding-right: 56px;

        .col-item {
            width: 100%;
            max-width: 100%;

            &__list {
                min-height: auto;
            }
        }
    }

    .rules-content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: auto;
        gap: 20px;

        .input-box {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        &__tooltip {
            display: flex;
            width: 200px;
            padding: 16px;
            flex-direction: column;
            gap: 8px;
            border-radius: 8px;
            background: #eff6ff;
            color: #636d65;
            
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;

            .checkbox-status input[type='checkbox']:checked+.checkbox-status-switch,
            .checkbox-status_disabled input[type='checkbox']:checked+.checkbox-status-switch {
                border: 2px solid #cb84ec;
                background: #cb84ec;
            }
        }

        .content-btn {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 8px;
            align-items: center;
        }

        &__content {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            margin-left: auto;
            margin-right: auto;
            min-width: calc(500px + 12px * 2 + 400px);

            .col-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 8px;
                border: 2px solid #edefed;
                background: #fafbfa;
                max-width: 240px;
                width: 240px;
                position: relative;

                &__header {
                    display: flex;
                    width: 100%;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-bottom: 2px solid #edefed;
                    border-radius: 8px 8px 0px 0px;
                    background: #fff;
                    min-height: 76px;

                    .title {
                        color: #64748b;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0;

                        span {
                            color: #020617;
                        }
                    }

                    .info-icon {
                        cursor: pointer;
                    }
                }

                &__btn_settings {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 4px;
                    border: 1px solid #edefed;
                    background: #fafbfa;

                    &:hover {
                        border: 1px solid #bbf7d0;
                    }

                    &:active {
                        border: 1px solid #00b45e;
                    }
                }

                &__list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 60vh;
                    min-height: 60vh;
                    padding: 8px;

                    &::-webkit-scrollbar {
                        width: 0;
                    }

                    .no-list {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        align-items: center;
                        width: 100%;
                        height: fit-content;
                        margin-top: 200px;
                        justify-content: center;

                        img {
                            width: 45px;
                            height: 45px;
                        }

                        .text {
                            color: #94a3b8;
                            text-align: center;
                            font-family: "Open Sans";
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }

                    &[loading='true'] {
                        pointer-events: none;
                        opacity: 0.3;
                    }
                }

                &__card {
                    display: flex;
                    width: auto;
                    padding: 12px;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 12px;
                    border-radius: 12px;
                    border: 2px solid #edefed;
                    background: #ffffff;
                    cursor: grab;
                    min-height: 106px;

                    .text {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        hyphens: auto;
                        color: #020617;
                        
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        min-height: 48px;
                    }

                    .status-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .status {
                            display: flex;
                            min-height: 18px;
                            height: 18px;
                            padding: 4px 8px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            background: #f1f5f9;
                            color: var(--slate-400, #94a3b8);
                            
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                        }

                        .card-disactive {
                            @extend .status;
                            background: #f1f5f9;
                            color: #94a3b8;
                        }

                        .card-active {
                            @extend .status;
                            background-color: #86efac;
                            color: #15803d;
                        }
                    }
                }

                .green-btn {
                    align-self: flex-end;
                }
            }
        }

        .column-group {
            display: flex;
            gap: 16px;
        }

        &__side-panel {
            display: flex;
            flex-direction: column;
            min-width: 224px;
            max-width: 224px;
            gap: 24px;
        }
    }

    .input-dinamyc-placeholder {
        flex: 1;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    &__btn-close {
        cursor: pointer;
        background-color: inherit;
        border: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        overflow-y: auto;
        display: flex;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #CACACA;
        padding: 12px 16px;
        background: #FFF;
        //  max-height: calc(100% - 96px - 84px - 32px);
        overflow-x: hidden;
        gap: 16px;
    }

    &__content-executor,
    &__content-settings {
        display: flex;
        flex-direction: column;
        //  flex-wrap: wrap;
        align-items: flex-start;
        height: 100%;
        gap: 16px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        //  max-height: calc(100% - 24px - 40px);
        padding-right: 8px;
    }

    &__btn-box {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: end;
    }

    .no-executor {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 100%;

        .text_grey {
            text-align: center;
            color: #94A3B8;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .notification-item,
    .notification-item_active {
        display: flex;
        gap: 8px;
        width: 150px;
        min-width: 150px;
        padding: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #EDEFFB;
        background: #FFF;
        cursor: pointer;
        position: relative;

        .text,
        .text_mp,
        .text_grey {
            overflow: hidden;
            color: #020617;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            word-break: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }

        .text_grey {
            color: #8c8c8c;
        }

        .text_mp {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        .changing-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            min-height: 32px;
            overflow: hidden;
        }

        &:hover {
            border: 2px solid #3900B1;
            transition: 0.5s;

        }

        &_active {
            border: 2px solid #3900B1;

            .text {
                display: inline-block;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            width: 40px;
            height: 40px;
        }
    }
}