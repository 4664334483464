.add-balance-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1002;
    display: flex;
    max-width: 480px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    // opacity: 0;
    // animation: fadeIn 0.3s ease-out forwards;

    // @keyframes fadeIn {
    //     from {
    //         opacity: 0;
    //     }

    //     to {
    //         opacity: 1;
    //     }
    // }

    &__img {
        width: 64px;
        height: 64px;
    }

    &__input-field {
        display: inline-block;
        max-width: 240px;
        text-align: center;
        color: #020617;
        font-size: 13px;
        border-radius: 8px;
        height: 40px;
        max-width: 240px;
        width: 100%;
        padding-right: 40px;
        padding-left: 16px;
        border: 1px solid #E0E7F2;

        &:hover {
            border: 1px solid #64748B;
        }

        &:focus {
            outline: 0;
            border: 1px solid #00B45E;
        }

        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


        &::placeholder {
            color: #64748B;
            font-size: 13px;
        }
    }

    .input-box {
        max-width: 240px;
        width: 100%;
        height: 40px;
        position: relative;

        &::after {
            content: 'руб.';
            display: inline-block;
            width: 25px;
            height: 16px;
            position: absolute;
            color: #64748B;
            font-size: 13px;
            top: 10px;
            right: 12px;
        }
    }

    .input-and-text-box {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }
}