.modal-creating-new-space {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    min-width: 360px;
    height: 100%;
    max-height: 900px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 20px;
    background: var(--white, #FFF);
    padding: 24px;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #020617;
            font-family: NunitoSans;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

        .input-dinamyc-placeholder {
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    &__id {
        align-items: center;
        background: #f9f9f9;
        border-radius: 4px;
        color: #636d65;
        cursor: pointer;
        display: flex;
        font-family: Golos Text;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        gap: 6px;
        height: 30px;
        justify-content: center;
        line-height: 18px;
        padding: 6px 8px;
        white-space: nowrap;

        .button-copy {
            position: relative;
        }
    }

    .input-dinamyc-placeholder {
        flex: 1;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    &__btn-close {
        cursor: pointer;
        background-color: inherit;
        border: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        overflow-y: auto;
        display: flex;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #CACACA;
        padding: 20px;
        background: #FFF;
        //  max-height: calc(100% - 96px - 84px - 32px);
        overflow-x: hidden;
        gap: 16px;
    }

    &__content-executor, &__content-settings {
        display: flex;
        flex-direction: column;
      //  flex-wrap: wrap;
        align-items: flex-start;
        height: 100%;
        gap: 16px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
      //  max-height: calc(100% - 24px - 40px);
        padding-right: 8px;
    }

    &__btn-box {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: end;
    }

    .no-executor {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 100%;

        .text_grey {
            text-align: center;
            color: #94A3B8;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .notification-item,
    .notification-item_active {
        display: flex;
        gap: 8px;
        width: 150px;
        min-width: 150px;
        padding: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #EDEFFB;
        background: #FFF;
        cursor: pointer;
        position: relative;

        .text,
        .text_mp,
        .text_grey {
            overflow: hidden;
            color: #020617;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            word-break: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }

        .text_grey {
            color: #8c8c8c;
        }

        .text_mp {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        .changing-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            min-height: 32px;
            overflow: hidden;
        }

        &:hover {
            border: 2px solid #3900B1;
            transition: 0.5s;

        }

        &_active {
            border: 2px solid #3900B1;

            .text {
                display: inline-block;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            width: 40px;
            height: 40px;
        }
    }
}