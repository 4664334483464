.editor-component,
.editor-component_disabled {
    display: flex;
    flex-direction: column;
    gap: 8px;



    &_disabled {
        cursor: default;
    }

    .redactor-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 4px;

        &__btn {
            cursor: pointer;
            background-color: inherit;
            border: none;
            color: #80C2FF;
            text-align: center;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        &__field {
            border-radius: 8px;
            background-color: #fff;
            border: 1px solid #E7E6E9;

            &:hover {
                transition: 0.3s;
                background: #f6f8fd;
            }
        }

        &__title {
            overflow: hidden;
            color: #475569;
            text-overflow: ellipsis;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        &__files {
            padding: 8px;
        }

        &__toolbar-bottom {
            display: flex;
            gap: 8px;
            width: 100%;
            justify-content: flex-end;
        }

        &__emoji-box {
            display: flex;
            position: relative;
            overflow-block: visible;
        }

        &__emoji-drop {
            display: flex;
            position: absolute;
            bottom: 100%;
            right: 0;
        }

        &__btn-emoji,
        &__btn-load,
        &__btn-send {
            border: none;
            cursor: pointer;
            background-color: inherit;
        }

        &__btn-load {
            display: inline-block;
            position: relative;
        }

        .ql-toolbar {
            display: none !important;

            .ql-snow {
                display: none;
            }
        }

        .ql-container {
            border-radius: 8px !important;
        }

        .text-content {
            width: 100%;
            height: auto;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 8px;

            .title {
                color: #475569;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .text {
                color: #53616E;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                width: 100%;
                padding: 8px;
                //    background: #fff;
                border-radius: 10px;
                //  border: 1px solid #E7E7E7;
                word-break: break-word;

                img {
                    max-width: 40%;
                }


            }

            .text a {
                color: #1890FF;
            }
        }

        .title_redactor {
            color: #8F99A3;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .ql-editor {
            padding: 8px;

            p {
                // display: inline-block;
            }
        }

        .ql-editor.ql-blank::before {
            font-style: normal;
            color: #53616E;
            font-size: 12px;
            font-weight: 400;
            font-family: "Open Sans";
            left: 8px;
        }

        .ql-container {
            max-height: 200px;
            overflow: auto;
        }

        .quill {
            display: flex;
            flex-direction: column;
            height: 100%;
            //  min-height: 180px;

            // &:hover {
            //     .ql-toolbar,
            //     .ql-container {
            //         border: 1px solid #64748B;
            //     }
            // }

            &:focus {

                .ql-toolbar,
                .ql-container {
                    border: 1px solid rgb(24, 144, 255)
                }
            }

            // Верхняя панель
            .ql-toolbar {
                display: block;
                background: #ffffff;
                border-top-left-radius: 0.5em;
                border-top-right-radius: 0.5em;
                border: 1px solid #E7E6E9;
                border-radius: 8px 8px 0 0;
            }

            // Контейнер содержимого текста
            .ql-container {
                height: calc(100%);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                // border: 1px solid #E7E6E9;
                border: none !important;
                background: #ffffff;
            }
        }

    }

    .btn-group {
        display: flex;
        gap: 20px;

        .link-blue {
            color: var(--button-primary-default, #80C2FF);
            text-align: center;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            &:hover {
                transition: 0.5s;
                color: #1890FF;
            }
        }
    }
}

// progress {
//     width: 100%;
//     height: 20px;
//     border-radius: 5px;
//     background-color: #f3f3f3;
//     border: 1px solid #ddd;
//     transition: none;
//     /* Без анимации по умолчанию */

//     &::-webkit-progress-bar {
//         background-color: #f3f3f3;
//         border-radius: 5px;
//     }

//     &::-webkit-progress-value {
//         background-color: #4caf50;
//         border-radius: 5px;
//         transition: inherit;
//     }

//     &::-moz-progress-bar {
//         background-color: #4caf50;
//         border-radius: 5px;
//         transition: inherit;
//         /* Плавная анимация */
//     }
// }