.modal-add-token {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 360px;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    min-width: 360px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: var(--white, #FFF);
    padding: 24px;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #020617;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    &__token {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        justify-content: space-between;
        word-break: break-all;
    }

    .selector_wrapper {
        max-width: 100%;
    }
}