.card-discount {
    display: flex;
    width: 210px;
    min-width: 145px;
    max-width: 445px;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #F8F9FE;
    min-height: 66px;
    max-height: 66px;
    justify-content: space-between;
    position: relative;

    &__title-card {
        overflow: hidden;
        color: #64748B;
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
    }

    &__discount-content {
        position: absolute;
        top: 2px;
        right: 12px;

        span {
            font-size: 13px;
        }
    }

    &__text {
        overflow: hidden;
        color: var(--slate-500, #64748B);
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }

    &__text_min {
        top: 100%;
        left: 0;
        position: absolute;
        color: var(--slate-400, #94A3B8);
        text-align: center;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 8px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__text-header {
        color: #64748B;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;

        span {
            color: #020617;
            font-weight: 500;
        }
    }
}