.show-img {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.comment__wrapper,
.comment__wrapper_author {
    display: flex;
    gap: 8px;
    background-color: #FFF;
    border-radius: 6px;
    padding: 5px 0px;
    border-radius: 6px;
    width: 94%;

    // img {
    //     width: 100%;
    // }

    &_author {
        margin-left: auto;
    }
}

.comment-item,
.comment-item_author {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    background: #F3F4F6;
    padding: 12px;
    border-radius: 8px;
    max-width: calc(100% - 24px);

    &__date-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
    }

    &__star {
        width: 12px;
        height: 12px;
    }

    &__reply-to {
        display: flex;
        flex-direction: column;
        background-color: #F2F2F2;
        padding: 8px 12px;
    }

    &__reply-to-author {
        color: #00B45E;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    &__reply-to-text {
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__group-btn {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__btn-answer {
        display: flex;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        background: #F3F4F6;
        border: none;
    }

    p {
        word-break: break-word;
    }

    &_author {
        background-color: #DCFCE7;
    }

    &__header {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__btn-group {
        display: flex;
        align-items: center;
        gap: 8px;

        .btn-text-grey {
            color: #8F99A3;
            font-family: "Open Sans";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .executor-placeholder {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
    }

    .text_date {
        color: #5C7189;
        font-family: "Golos Text";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
    }

    .text_name {
        color: #00B45E;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    .text {
        color: #000;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        img {
            width: 100%;
        }
    }
}