@import 'src/common/styles/svg_img.scss';


.footer {

    // Заголовок
    &__title {
        margin-bottom: 8px;
        color: var(--text-primary-blue, #020617);
        font-family: "Open Sans";
        font-size: 15px;
        min-height: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }


    // нижняя часть подвала

    &__item {
        display: block;
        width: auto;
        height: auto;
    }

    &__copyright {
        color: grey;
        display: inline-block;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75em;
        line-height: 15px;
        white-space: nowrap;
    }
}

.icon_square {
    display: block;
    margin: auto;
}

.address__link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 20px;
    color: #121212;

    &_tel,
    &_mail,
    &_send,
    &_location {
        &::before {
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            width: 16px;
            height: 16px;
            margin-right: 0.75em;
            vertical-align: middle;
        }
    }



    &_send::before {
        background-image: url($send_message);
    }

    &_tel::before {
        background-image: url($tel);
    }

    &_mail::before {
        background-image: url($mail)
    }

    &_location::before {
        background-image: url('./img/Icon_location.png')
    }

}



@media (max-width: 1200px) {
    .footer__nav {
        display: flex;
        justify-content: space-evenly;
    }

    .copyright {
        & .country {
            justify-self: flex-end;
            margin-left: 0;
            border-left: none;
            padding-right: 2em;
        }
    }
}

@media (max-width: 768px) {
    .copyright {
        flex-direction: column;
        align-items: center;

        & .footer__link {
            border: none;
        }

        .footer__copyright {
            border: none;
        }

        & .country {
            justify-self: flex-end;
            margin-left: auto;
            border-left: none;
            padding-right: 2em;
        }
    }
}