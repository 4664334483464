.input-list-array {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: var(--space-x2, 8px) var(--space-x5, 12px);
    align-items: center;
    gap: var(--space-x2, 8px);
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E0E7F2;
    background: var(--white, #FFF);
    color: #020617;
    font-size: 13px;
    font-weight: 400;
    position: relative;

    &:focus-within {
        border: 1px solid #57CC6A;

        &:hover {
            border: 1px solid #57CC6A;
        }
    }

    &:focus {
        outline: none;
        border: 1px solid #57CC6A;

        &:hover {
            border: 1px solid #57CC6A;
        }
    }

    &:hover {
        border: 1px solid #64748B;
    }

    &__value-chip {
        display: flex;
        gap: 4px;
        padding: 1px 4px;
        border-radius: 4px;
        background-color: #F6F7F6;
        border: 1px solid #E0E7F2;

        span {
            word-wrap: break-all;
        }
    }

    &__close-chip {
        background-color: inherit;
        border: none;
        cursor: pointer;
    }

    &__disabled {
        background-color: #f6f8fd;
        border: 1px solid #f6f8fd;
        border-radius: 8px;

        &:focus,
        &:hover {
            border: 1px solid #f6f8fd;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            &:hover {
                border: 1px solid #f6f8fd;
            }
        }
    }

    &__err-box {
        background-color: #FFF5F5;
        border: 1px solid #FFD8D8;
        border-radius: 8px;

        &:focus,
        &:hover {
            border: 1px solid #FFD8D8;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            &:hover {
                border: 1px solid #FFD8D8;
            }
        }


        .placeholder-dinamyc_min {
            label {
                &:after {
                    background-color: #FFF5F5;
                }
            }
        }
    }

    &__input {
        background-color: inherit;
        border: none;
        width: 100%;

        &:focus {
            outline: none;

            &:hover {
                outline: none;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        &:auto-fill {
            box-shadow: inset 0 0 0 100px var(--background-color);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &__info {
            display: flex;
            position: absolute;
            //   top: calc(50% - 8px);
            width: auto;
            height: auto;
            right: 20px;
            gap: 4px;
            align-items: flex-start;
            z-index: 10000;

            &:hover {
                .tooltip {
                    display: flex;
                }
            }

            .tooltip {
                padding: 14px;
                position: absolute;
                min-width: 200px;
                max-width: 200px;
                left: -92px;
                border: 2px solid #B2DAFF;
                fill: #FFF;
                stroke-width: 2px #B2DAFF solid;
                margin-top: 8px;
                z-index: 100000;
                width: fit-content;
                border-radius: 8px;
                height: auto;
                // position: absolute;
                background-color: #fff;
                display: none;
                flex-direction: column;
                gap: 4px;

                .title {
                    color: var(--main-menu-primary-text, #020617);
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .text {
                    color: var(--main-menu-teritary-text, #94A3B8);
                    /* Button link/Medium */
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    /* 114.286% */
                }

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    background: rgb(255, 255, 255);
                    top: 40px;
                    left: calc(50% - 5px);
                    top: -6px;
                    width: 10px;
                    height: 10px;
                    border: 2px solid #B2DAFF;
                    border-right: none;
                    border-bottom: none;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    z-index: 0;
                }

            }

            .btn__info {
                width: 16px;
                height: 16px;
                border: none;
                background-color: inherit;
                background-image: url('./img/info_tooltip.svg');
                background-repeat: no-repeat;
                background-size: 16px;
                background-position: center;
            }

            .text_red {
                color: #FF4D4F;
                font-size: 11px;
                margin: 0;
            }
        }
    }

    &__err {
        position: absolute;
        max-height: 30px;
        height: 100%;
        overflow-x: auto;
        top: calc(100% + 2px);
        left: 8px;
        display: flex;
        gap: 4px;
        align-items: flex-start;
    }

    &__text-tooltip {
        color: #94A3B8;
        position: absolute;
        max-height: 30px;
        height: 100%;
        overflow-x: auto;
        top: calc(100% + 2px);
        left: 8px;
        font-size: 11px;
    }

    &__text-red {
        color: #FF4D4F;
        font-size: 11px;
        margin: 0;
    }

    &__clean-value {
        display: flex;
        gap: 12px;
        position: absolute;
        top: calc(50% - 8px);
        right: 10px;
        display: flex;
        gap: 4px;
        align-items: flex-start;

        .btn__show,
        .btn__hide {
            width: 16px;
            height: 16px;
            border: none;
            background-color: inherit;
            background-image: url('./img/show.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }

        .btn__hide {
            background-image: url('./img/hide.svg');
        }
    }

    &__btn-clean {
        width: 16px;
        height: 16px;
        border: none;
        background-color: inherit;
        background-image: url('./img/close_grey.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center;
    }

    &__info {
        display: flex;
        position: absolute;
        //   top: calc(50% - 8px);
        width: auto;
        height: auto;
        right: 20px;
        gap: 4px;
        align-items: flex-start;
        z-index: 10000;

        &:hover {
            .tooltip {
                display: flex;
            }
        }

        .tooltip {
            padding: 14px;
            position: absolute;
            min-width: 200px;
            max-width: 200px;
            left: -92px;
            border: 2px solid #B2DAFF;
            fill: #FFF;
            stroke-width: 2px #B2DAFF solid;
            margin-top: 8px;
            z-index: 100000;
            width: fit-content;
            border-radius: 8px;
            height: auto;
            // position: absolute;
            background-color: #fff;
            display: none;
            flex-direction: column;
            gap: 4px;

            .title {
                color: var(--main-menu-primary-text, #020617);
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .text {
                color: var(--main-menu-teritary-text, #94A3B8);
                /* Button link/Medium */
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 114.286% */
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                background: rgb(255, 255, 255);
                top: 40px;
                left: calc(50% - 5px);
                top: -6px;
                width: 10px;
                height: 10px;
                border: 2px solid #B2DAFF;
                border-right: none;
                border-bottom: none;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                z-index: 0;
            }

        }

        .btn__info {
            width: 16px;
            height: 16px;
            border: none;
            background-color: inherit;
            background-image: url('./img/info_tooltip.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }

        .text_red {
            color: #FF4D4F;
            font-size: 11px;
            margin: 0;
        }
    }
}



.placeholder-dinamyc,
.placeholder-dinamyc_min,
.placeholder-dinamyc_min-blue {
    width: 100%;
    position: absolute;
    top: calc(50% - 8px);
    left: 14px;
    pointer-events: none;

    label {
        position: absolute;
        color: #64748B;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        z-index: 1;
        transition: 0.3s;
    }

    &_min,
    &_min-blue {
        display: inline-block;
        top: -5px;
        z-index: 1;
        left: 12px;

        label {
            position: absolute;
            z-index: 0;
            color: #53616E;
            font-family: "Open Sans";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 10px;
            transition: 0.3s;
            padding-left: 6px;
            padding-right: 6px;

            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                margin-top: 3px;
                left: 0;
                top: 0;
                width: 100%;
                height: 6px;
                background-color: white;
                z-index: -1;
            }
        }
    }

    &_min-blue {
        label {
            color: #0B110B;
        }
    }
}




.input-err {
    div {
        border: 2px solid #FFD8D8;
        border-radius: 8px;

        input {
            background-color: #FFF5F5;

            &:focus,
            &:hover {
                border: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }
}


.size-s {
    flex: 1;
    min-width: 100px;
    width: auto;

    .input-dynamic {
        height: 40px;

        &__err {}
    }
}

.size-l {
    flex: 1;
    min-width: 100px;
    width: auto;

    .input-dynamic {
        height: 56px;

        &__err {}
    }
}

.text_tooltip-mui {
    padding-left: 8px;
    color: #94A3B8;
    height: 16px;
    font-family: "Inter";
    font-size: 11px;
    height: auto;
}