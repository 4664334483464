.promotion_page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__group-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;
  }

  .label-check {
    color: #020617;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .title_main {
    color: #020617;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 12px;
  }
}
