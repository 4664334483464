// Основные переменные
$card-width: 240px;
$img-height: 112px;
$border-radius: 8px;
$transition-duration: 0.3s;

// Цвета
$color-primary: #1890FF;
$color-hover-bg: #E7F1FF;
$color-hover-header-bg: #F5F9FF;
$color-border: #E0E7F2;
$color-border-hover: #1890FF;
$color-title: #020617;
$color-divider: #EDEFFB;

// Шрифты
$font-family-primary: 'NunitoSans', sans-serif;
$font-size-title: 14px;

.project-card {
    cursor: pointer;
    width: $card-width;
    min-width: $card-width;
    background: #FFF;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:hover {
        transition: $transition-duration;
        background: $color-hover-bg;
        border-color: $color-border-hover;

        .project-card__header {
            background: $color-hover-header-bg;
        }
    }

    // Верхняя часть (заголовок + изображение)
    &__top-content {
        padding: 12px 20px 20px;
        background: #F8F9FC;
        border-radius: $border-radius $border-radius 0 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 20px;
    }

    &__img-box {
        height: $img-height;
        min-height: $img-height;
        border-radius: $border-radius;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    // Средняя часть (название проекта)
    &__medium-content {
        padding: 12px;
    }

    &__title {
        color: $color-title;
        font-family: $font-family-primary;
        font-size: $font-size-title;
        font-weight: 500;
        line-height: 20px;
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    // Нижняя часть (участники)
    &__bottom-content {
        padding: 12px;
        margin-top: auto;
        border-top: 1px solid $color-divider;
    }

    &__employees-container {
        display: flex;
        gap: 4px;
    }

    &__sum_executor {
        font-size: 11px;
    }

    &__sum-executor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #F1F5F9;
        color: #2E3552;
        text-align: center;
        font-family: NunitoSans;
        font-size: 10px;
        font-style: normal;
        font-weight: 800;
        line-height: 8px;
        margin-left: 8px;
        cursor: pointer;

        span {
            color: rgba(46, 53, 82, 0.60);
        }
        color: #53616E;
        font-family: "Open Sans";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-left: 8px;
    }

    &__employees-container {
        display: flex;
        width: auto;
        overflow: hidden;
        align-items: center;
        min-width: 190px;

        .tooltip-container {
            margin-left: -12px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }
}