.tabs-and-chat {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
}

.chat-review {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    max-height: 730px;
    min-height: 730px;
    border: 1px solid #EDEFED;
    border-radius: 8px;
    overflow: hidden;

    &__no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 60px;
        color: #64748B;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        img {
            width: 100px;
            height: 100px;
        }
    }

    .input-dynamic-width-bar {
        width: 100%;
    }

    .search-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .search-results {
            background: #FFF;
            overflow: hidden;
            width: 100%;
            border: 2px solid #EDEFFB;
            z-index: 10;

            &__header {
                width: 100%;
                padding: 8px 16px;
                border-bottom: 2px solid #EDEFFB;

                .text_count {
                    color: #1E293B;
                    
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 400px;
                overflow-x: hidden;
                overflow-y: auto;

                .search-item {
                    display: flex;
                    gap: 12px;
                    padding: 8px 16px;
                    cursor: pointer;
                    border-bottom: 2px solid #EDEFFB;


                    .img-box {
                        width: 82px;
                        height: 82px;
                        min-width: 82px;
                        border-radius: 4px;
                        border: 1px solid #C5CCD3;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        width: calc(100% - 94px);

                        .title {
                            padding: 8px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #020617;
                            
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }

                        .brand-name,
                        .article-name {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            align-items: center;
                            padding: 4px 8px;

                            &:nth-child(1) {
                                margin-top: 4px;
                            }

                            .text {
                                white-space: nowrap;
                                overflow: hidden;
                                color: #4750A6;
                                text-overflow: ellipsis;
                                
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 12px;
                                letter-spacing: -0.22px;
                            }
                        }

                        .article-name {
                            .text {
                                color: #64748B;
                                
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 14px;

                                span {
                                    color: #3900B1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .loader-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__left-content {
        width: 45%;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;

        .toolbar-panel {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            background-color: #fff;
            border-bottom: 1px solid #EDEFED;
            justify-content: space-between;
            min-height: 74px;
            max-height: 74px;
            padding: 12px 16px;

            &__bottom {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 12px;
                justify-content: space-between;

                .btn-group-panel {
                    display: flex;
                    width: auto;
                    background-color: white;
                    border-radius: 8px;

                    .btn-count,
                    .btn-count_active {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        justify-content: center;
                        flex-wrap: nowrap;
                        gap: 8px;
                        height: 40px;
                        padding-left: 20px;
                        padding-right: 20px;
                        background-color: white;
                        border: none;
                        border-radius: 8px;

                        .text {
                            color: #4750A6;
                            text-align: center;
                            
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 12px;
                        }

                        span {
                            @extend .text;
                            color: #110057;
                        }

                        &:hover,
                        &_active {
                            transition: 0.5s;
                            background: #3900B1;

                            .text,
                            span {
                                color: #fff;
                            }
                        }
                    }
                }

                .btn-group-sort {
                    display: flex;
                    gap: 12px;
                    align-items: center;

                    .btn-sort {
                        display: flex;
                        color: inherit;
                        color: #4750A6;
                        
                        white-space: nowrap;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 12px;
                        background-color: inherit;
                        border: none;
                        gap: 8px;
                        align-items: center;
                        flex-wrap: nowrap;
                    }
                }
            }



            .input-field-wrapper {
                max-width: 100%;
            }

            .sort-box {
                display: flex;
                align-items: center;
                gap: 16px;

                a {
                    white-space: nowrap;
                    color: var(--main-menu-teritary-text, #94A3B8);
                    font-family: "Open Sans";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }

                .active {
                    color: #020617;
                }

            }
        }

        .list-message {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            height: calc(100% - 74px);
            border-radius: 8px;
            background: #FFF;

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow: auto;
                overflow-x: hidden;
                max-height: 857px;
                height: 100%;
                gap: 4px;

                &::-webkit-scrollbar {
                    display: block;
                }

            }
        }
    }

    &__right-content {
        width: 55%;
    }

    &__general-information,
    &__general-information_hide {
        display: flex;
        flex-direction: column;
        width: 380px;
        height: 100%;
        gap: 24px;
        border-radius: 0px 9px 8px 0px;
        border-left: 1px solid #EDEFED;
        background: #FFF;
        min-width: 380px;
        max-width: 380px;
        padding: 22px 24px;
        overflow: auto;
        position: relative;
        transition: 0.5s;
        overflow-x: hidden;

        &_hide {
            transition: 0.5s;
            max-width: 0;
            min-width: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .btn-back {
            border: none;
            background-color: inherit;
            cursor: pointer;
            top: 18px;
            right: 24px;
            position: absolute;
            transform: rotate(180deg);
        }

        .title_info {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            min-width: calc(380px - 48px);
        }

        .info-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
            min-width: calc(380px - 48px);

            &__item {
                display: flex;
                align-items: end;
                justify-content: space-between;
                gap: 12px;
            }

            .text_label {
                color: #606D68;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: -0.28px;
                width: inherit;
            }

            .text_value,
            .text_value-blue {
                color: #0B110B;
                text-align: right;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 12px;
                letter-spacing: -0.28px;
                max-width: inherit;
            }

            .text_value-blue {
                color: #0009E8;
            }

        }
    }

    .send-img {
        width: 20px;
        height: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .chat {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-left: 1px solid #EDEFED;
        background: #fff;
        overflow: hidden;

        &__header {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
            min-height: 74px;
            max-height: 74px;
            padding: 12px 16px;
            width: 100%;
            background-color: #fff;
            border-bottom: 1px solid #EDEFED;
            position: relative;

            .title-and-rating {
                display: flex;
                justify-content: space-between;
                gap: 24px;
                align-items: center;


                .title_product {
                    width: 100%;
                    overflow: hidden;
                    color: #020617;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    word-break: break-word;
                    min-height: 30px;
                }
            }

            .rating-and-date {
                display: flex;
                flex-direction: column;
                margin-left: auto;

                .rating-color-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 8px 0px 8px;
                    width: 60px;
                    min-width: 60px;
                    height: 33px;
                    background: #EBEFEB;
                    border-radius: 2px;
                    gap: 6px;
                }

                .text_rating {
                    overflow: hidden;
                    color: #0B110B;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                }

                .rating {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                        color: var(--text-main-text, #020617);
                        /* Table/Content/M */
                        font-family: IBMPlexSans;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                    }
                }
            }

            .bottom-info {
                display: flex;
                gap: 24px;
                align-items: center;

                .btn-info {
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    color: #636D65;
                    font-family: NunitoSans;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    border: none;
                    background-color: inherit;
                    margin-left: auto;

                    &:hover {
                        transition: 0.3s;
                        color: #0B110B;
                    }
                }

                .text_date {
                    margin-top: auto;
                    color: #020617;
                    text-align: right;
                    
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: -0.22px;

                    span {
                        font-weight: 400;
                    }
                }
            }


            .rating-container {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border: 1px solid #090C2E;
                background: #FFF;
                border-radius: 8px;
                height: 40px;
                gap: 8px;
            }

            .text {
                color: var(--main-menu-primary-text, #020617);
                font-family: "Open Sans";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            border: rgba(251, 251, 253, 0.29);
            gap: 24px;
            padding: 24px;
            overflow: auto;
            width: 100%;
            height: 100%;
            background-color: #FAFBFA;
            ;
        }

        .date-messages {
            border-radius: 100px;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            padding: 8px 12px;
            background: #FFF;
            box-shadow: 3px 5px 10px 0px rgba(13, 17, 52, 0.16);
            color: #2E3552;
            text-align: center;
            
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            position: relative;
            z-index: 1;

            &:first-child {
                margin-top: 0;
            }
        }

        .product-card {
            display: flex;
            width: 100%;
            padding: 12px 24px;
            min-width: 0;
            gap: 12px;
            border-radius: 12px;
            background: var(--main-menu-active-row, #E6F7FF);
            margin-top: 24px;

            .icon-container {
                width: 52px;
                max-width: 52px;
                min-width: 52px;
                border-radius: 8px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .top-and-date {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;

                    p {
                        color: var(--main-menu-teritary-text, #94A3B8);
                        font-family: "Open Sans";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        span {
                            color: var(--main-menu-secondary-text, #475569);
                            font-family: "Open Sans";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }

                    .rating {
                        display: flex;
                        align-items: center;
                        gap: 2px;

                        span {
                            color: var(--text-main-text, #020617);
                            /* Table/Content/M */
                            font-family: IBMPlexSans;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px;
                        }
                    }

                    .date {
                        color: var(--main-menu-secondary-text, #475569);
                        text-align: right;
                        /* Основной текст/Мелкий */
                        font-family: "Open Sans";
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.24px;
                    }
                }

                .info-content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    p {
                        color: var(--main-menu-teritary-text, #94A3B8);
                        font-family: "Open Sans";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        span {
                            color: var(--main-menu-secondary-text, #475569);
                            font-family: "Open Sans";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }


        }

        .message-and-icon {
            display: flex;
            gap: 12px;
            width: 100%;
            align-items: flex-start;

            .img-box {
                width: 36px;
                min-width: 36px;
                height: 36px;
                border-radius: 4px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .delimiter-user {
            margin-top: 26px;
            margin-bottom: 26px;
            height: 1px;
            width: 100%;
            background-color: #EAEBFC;
            position: relative;

            .text_date {
                white-space: nowrap;
                width: fit-content;
                position: relative;
                background-color: #fff;
                color: #2E3552;
                text-align: center;
                
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                top: -6px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 24px;
                padding-right: 24px;
                z-index: 1;
            }
        }

        .message-item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: 70%;
            width: auto;

            .img-group {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 10px;
                align-items: center;
                margin-left: 48px;

                .img-box {
                    width: 64px;
                    height: 64px;
                    border-radius: 4px;
                    border: 1px solid #C5CCD3;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }


            .info-box {
                display: flex;
                flex-direction: column-reverse;
                align-items: end;
                justify-content: space-between;
                width: calc(100% - 48px);
                gap: 4px;
                margin-left: 48px;


                .btn-edit {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    border: none;
                    background-color: inherit;
                    cursor: pointer;
                    overflow: hidden;
                    color: #94A3B8;
                    text-overflow: ellipsis;
                    
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                }

                .date {
                    color: #475569;
                    
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }
            }
        }

        .message-item-user {
            .info-box {
                align-items: start;
            }
        }

        .answer {
            display: flex;
            min-height: 96px;
            gap: 16px;
            width: calc(100% - 28px);
            padding: 20px;
            align-items: center;
            border-radius: 8px;
            background: var(--day-carbone-10, #FFF);
            box-shadow: 0px 2px 12px 0px rgba(24, 144, 255, 0.04);
            margin-left: 14px;
            margin-right: 14px;
            margin-top: 8px;

            .file-box {
                width: 30px;
                min-width: 30px;
                height: 30px;
                position: relative;
                overflow: hidden;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0;
                    cursor: pointer;
                }

                .btn__attach {
                    width: 30px;
                    height: 30px;
                    border: none;
                    background-color: inherit;
                    background-image: url('./img/attach_icon.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            textarea {
                width: 100%;
                border: none;
                outline: none;
                resize: none;
                color: var(--text-tertiary, #131517);
                vertical-align: middle;
                /* Основной текст/Подписи */
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: var(--text-tertiary, #A1A7B3);
                    /* Основной текст/Подписи */
                    font-family: "Open Sans";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    .action-messages {
        opacity: 0;
        visibility: hidden;
        display: flex;
        gap: 8px;
        align-items: center;
        position: absolute;
        top: calc(50% - 9px);
        left: calc(100% + 12px);
        transition: 0.3s;
    }

    .forward-message,
    .add-to-templates {
        display: flex;
        align-items: center;
        gap: 4px;

        .text {
            white-space: nowrap;
            color: #8F99A3;
            
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }

        &__btn {
            background-image: url('./img/add_icon.svg');
            background-size: 18px;
            width: 18px;
            height: 18px;
            border: none;
            background-color: inherit;
        }

        &__tooltip {
            opacity: 0;
            visibility: hidden;
            display: inline-flex;
            width: fit-content;
            padding: 6px;
            border-radius: 2px;
            border: 1px solid #C5CCD3;
            background: #F1F5F9;
            color: var(--primary-primary-700, #004280);
            text-align: center;
            font-family: "Open Sans";
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            max-width: 260px;
            width: max-content;
        }
    }

    .forward-message {
        &__btn {
            background-image: url('./img/forward_icon.svg');
        }

        &:hover {
            .forward-message__tooltip {
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
            }
        }
    }

    .add-to-templates {
        &:hover {
            .add-to-templates__tooltip {
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
            }
        }
    }
}

.message,
.message_active {
    display: flex;
    width: 100%;
    gap: 16px;
    background: #fff;
    cursor: pointer;
    padding: 12px 16px;
    padding-left: 7px;
    position: relative;
    max-height: 146px;
    min-height: 146px;
    overflow: hidden;
    border-left: 5px solid #ffffff;

    &__card-moderation {
        display: flex;
        padding: 4px 8px;
        border-radius: 4px;
        width: fit-content;
        align-items: center;
        justify-content: center;
        background: #F0F2F4;
        color: #000000;
        text-align: center;
        
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }

    &_active,
    &:hover {
        transition: 0.5s;
        background: #F2F4F2;
        border-left: 5px solid #F2F4F2;
    }

    &_active {
        border-left: 5px solid #8F99A3;

        &:hover {
            border-left: 5px solid #8F99A3;
        }
    }

    .icon-container {
        width: 86px;
        min-width: 86px;
        height: 86px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #C5CCD3;
        position: relative;

        .not-answered,
        .answered,
        .reply-is-overdue {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(100% - 18px);
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            background: #86EFAC;
            color: #15803D;
            text-align: center;
            
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
        }

        .not-answered {
            background-color: #FDBA74;
            color: #9A3412;
        }

        .reply-is-overdue {
            background-color: #F1F5F9;
            color: #94A3B8;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }



    .rating-and-date {
        display: flex;
        flex-direction: column;
        width: calc(115px - 22px);
        min-width: calc(115px - 22px);
        margin-left: auto;

        .rating-color-box {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 8px 0px 8px;
            width: 70px;
            min-width: 70px;
            height: 44px;
            position: absolute;
            border-radius: 2px;
            background: #EBEFEB;
            top: 12px;
            right: 16px;
            gap: 6px;
        }

        .text_rating {
            overflow: hidden;
            color: #020617;
            text-overflow: ellipsis;
            
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
        }

        .text_date {
            margin-top: auto;
            color: #020617;
            text-align: right;
            
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: -0.22px;

            span {
                font-weight: 400;
            }
        }

        .rating {
            display: flex;
            align-items: center;
            gap: 2px;

            span {
                color: var(--text-main-text, #020617);
                /* Table/Content/M */
                font-family: IBMPlexSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
            }
        }
    }

    .info-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        overflow: hidden;
        gap: 8px;

        .brand-name,
        .category-name {
            display: flex;
            align-items: center;
            gap: 6px;
            align-items: center;

            &:nth-child(1) {
                margin-top: 4px;
            }

            .text {
                white-space: nowrap;
                overflow: hidden;
                color: #4750A6;
                text-overflow: ellipsis;
                
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: -0.22px;
            }
        }

        .title {
            color: #0B110B;
            color: #0B110B;
            text-overflow: ellipsis;
            font-family: NunitoSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
        }

        .text_feedback {
            margin-top: auto;
            display: -webkit-box;
            -webkit-line-clamp: 1; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
            color: #020617;
            text-overflow: ellipsis;
            
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;

            span {
                color: #636D65;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: -0.22px;
            }
        }
    }
}

// Стили для сообщений внутри контента чата

.message-user,
.message-support {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: fit-content;
    padding: 12px 24px;
    border-radius: 0 16px 16px 16px;
    background: #DDECFF;
    align-items: end;

    .bottom-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
        margin-left: auto;
    }

    .date_create {
        overflow: hidden;
        color: #0B110B;
        text-align: right;
        text-overflow: ellipsis;
        
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-left: auto;
    }

    .text {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #090C2E;
        text-align: left;
        
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}

.message-user {
    margin-left: auto;
    position: relative;
    border-radius: 16px 0 16px 16px;
    background-color: rgba(236, 113, 0, 0.12);


    .action-messages {
        left: calc(0px - 54px);
    }

    &:hover {
        .action-messages {
            opacity: 1;
            visibility: visible;
            transition: 0.3s;

        }
    }
}

.message-support {
    margin-right: auto;
    position: relative;
    align-items: start;



    &:hover {
        .action-messages {
            opacity: 1;
            visibility: visible;
            transition: 0.3s;
        }
    }

    .rating-content {
        display: flex;
        gap: 2px;
        align-items: center;

    }
}