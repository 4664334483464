.files-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 16px;

    &__title {
        font-weight: 400;
        font-size: 18px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: calc(400px - 40px);
        .file-item-component {
            border-bottom: 1px solid #E0E7F2;

            &:nth-child(1) {
                border-top: 1px solid #E0E7F2;
            }
        }

        .paginator {
            margin-top: auto;
        }
    }

    &__no-file {
        color: #767676;
        font-size: 13px;
    }

    &__drop-zone {
        position: relative;
        width: fit-content;

        .drop-zone {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            min-height: 0;
            min-width: 0;
            padding: 0;
            overflow: hidden;
            opacity: 0;
        }
    }

    &__snackbar-row {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}