.cup-component {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    border-radius: 8px;
    border: 2px solid #EDEFFB;
    background: #FFF;
    box-sizing: border-box;
    padding: 1em 2em;
    min-height: 400px;
    
    * {
        font-family: IBMPlexSans;
        color: #475569;
    }

    &__title {
        padding: 8px 8px;
        padding-bottom: 0;
        color: #020617;
        
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    &__header {
        display: flex;
        width: '100%';
        justify-content: space-between;
        gap: 4em;
        padding-bottom: 0.5em;
        border-bottom: 1px solid #E0E7F2;

        span {
            font-weight: bold;
        }
    }

    &__content {
        display: flex;
        height: 100%;
    }

    .no-data {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 22px;
    }

    &_parametr {
        display: flex;
        min-width: 34%;
        max-width: 60%;
        justify-content: start;
        position: relative;
        cursor: default;

        .tooltip_black {
            opacity: 0;
            visibility: hidden;
            background-color: #4A4A4A;
            border-radius: 2px;
            color: white;
            position: absolute;
            top: calc(100% + 10px);
            left: calc(10px);
            z-index: 100;
            width: max-content;
            max-width: 250px;
            height: auto;
            padding: 2px 4px;
            border: 1px solid #E0E7F2;
        }

        &:hover {
            .tooltip_black {
                opacity: 1;
                visibility: visible;
                transition: 0.5s;
            }
        }
    }

    .right {
        min-width: 25%;
        max-width: 25%;
        justify-content: end;

        .tooltip_black {
            right: 0;
            left: none;
        }
    }

    .units-holder {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 0.75em;
        min-width: 100%;
        min-height: 5em;
        background-color: white;
        border-radius: 8px;
    }

    .unit-container {
        display: flex;
        width: 100%;
        gap: 14px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 4px;

        .unit-icon{
            width: 40px;
            height: 40px;
        }

        .unit-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            color: #6374BC;
            display: flex;
            gap: .5em;
            align-items: center;

            span {
                color: #C5CCD3;
            }
        }

        .medium {
            font-weight: 500;
            color: #475569;
        }

        .dynamic-box {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .unit-bar {
            width: 50%;
            display: flex;


            &_color {
                height: 14px;
            }
        }
    }

    .loader_wrapper {
        border: none;
    }
}