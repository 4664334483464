.card-executor {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 196px;
    min-width: 196px;
    padding: 14px 12px;
    border-radius: 8px;
    background: #FFF;
    position: relative;
    border: 1px solid #EDEFED;
    cursor: pointer;

    &:hover {
        transition: 0, 5s;
        border: 1px solid #B8B8B8;
        background: #F2F4F2;
    }

    .executor-absolute-box {
        position: absolute;
        top: -18px;
        right: 16px;
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .content-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        justify-content: center;
        font-weight: 400;
    }

    .tooltip-black {
        display: none;
    }


    &__btn-red,
    &__btn-green {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        background: #F4DBDA;
        color: #E11034;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        border: none
    }

    &__btn-green {
        background-color: rgba(121, 245, 121, 0.48);
        color: #347A40;
    }

    &__name {
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        word-break: break-word;
        min-height: 32px;
    }

    &__email {
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        color: #3C5484;
        word-wrap: break-word;
        font-family: NunitoSans;
        hyphens: auto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.36px;
        word-break: break-word;
        min-height: 32px;
    }

    &__btn {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        color: #1890FF;
        
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background-color: inherit;
        border: none;
        padding-top: 8px;
        border-top: 2px solid #EDEFFB;
    }
}