.modal-adding-media {
    max-width: 1000px;
    width: 100%;
    min-width: 360px;
    gap: 24px;
    padding: 24px;
    min-height: 360px;
    height: auto;
    align-items: center;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 120px);

    .title {
        width: 100%;
        color: #020617;
        text-align: center;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E0E7F2;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        height: auto;
        overflow: auto;

        .photo-container,
        .video-container {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;


            &__content {
                display: flex;
                flex-direction: column;
                max-height: 150px;
                flex-wrap: wrap;
                gap: 8px;
            }

            &__content-min {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .file-box {}
            }

            .title {
                color: #6B8CA9;
                text-align: center;
                
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                border: none;
                padding: 0;
            }
        }

        .drop-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 12px;
            width: 100%;
        }
    }



    .btn-group {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-top: 12px;
        gap: 12px;
    }
}


.file-box,
.file-box_err,
.file-box_big {
    display: flex;
    min-width: 78px;
    width: 78px;
    height: 71px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #EDEFFB;
    background: #FFF;
    position: relative;
    overflow: hidden;
    position: relative;

    .close-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;

        .close-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 1;
        }
    }

    &:hover {
        .close-box {
            transition: 0.3s;
            opacity: 1;
        }
    }

    &_big {
        min-width: 160px;
        width: 160px;
        height: 152px;
    }

    &_err {
        border: 1px solid #EF4444;
    }

    &[hover='true'] {
        background-color: white;
        transition: 0.5s;
    }



    .input-file {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }

    .icon-add {
        width: 24px;
        height: 24px;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}