.modal-add-new-wiki {
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    min-width: 360px;
    background-color: white;
    border-radius: 10px;
    max-height: calc(100vh - 120px);
    gap: 16px;
    max-width: 1136px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;
    min-height: 700px;
    max-height: 700px;

    .select_executor {
        width: 100%;

        &__content {
            gap: 24px;
        }
    }

    &__date-create {
        color: #767676;
        font-size: 13px;
    }

    &__header {
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: space-between;

        .input-dinamyc-placeholder {
            max-width: 290px;
        }
    }

    &__content {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        gap: 16px;
        background: #FFF;
        overflow: hidden;

        .input-field-wrapper {
            max-width: 100%;
        }
    }

    &__cards {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 24px;
        overflow: auto;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 16px 32px;
        border-top: 2px solid #EDEFED;
    }

    &__row-input {
        display: flex;
        gap: 12px;
        width: 100%;
    }
}