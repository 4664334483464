.tariffs-plan {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    min-width: 1110px;

    &__title {
        color: black;
        font-family: "Golos Text";
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
    }

    &__content {
        width: 100%;
    }

    &__my-plan {
        display: flex;
        width: auto;
        padding: 5px 13px;
        align-items: center;
        gap: 9px;
        border-radius: 8px;
        background: #FFF;
        border: 1px solid #EDEFED;
        flex-wrap: wrap;
        color: #000;
        text-align: right;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        span {
            color: #FF7A00;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
        }

        &__content {
            width: 100%;
        }
    }

    &__header {
        display: flex;
        gap: 16px;
        max-width: 100%;
    }

    &__cards {
        display: flex;
        justify-content: flex-end;

        gap: 16px;
        max-width: 100%;
    }

    &__item {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__item-min,
    &__item-min_hide {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        min-height: 36px;
        border-bottom: 1px solid #C5C5C5;
    }

    &__items,
    &__items_hide {
        transition: 0.5s;
        max-height: 1800px;

        &_hide {
            overflow: hidden;
            max-height: 0;
            padding: 0;
            transition: 0.5s;
        }

        .tariffs-plan__item-min {
            padding-top: 2px;
            padding-bottom: 2px;
            min-height: 36px;
        }
    }

    &__label-min {
        display: flex;
        gap: 6px;
        color: #333;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
    }

    &__item-section,
    &__item-section-label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 240px;
        max-width: 240px;
        margin-left: 16px;
    }

    &__item-section-label {
        justify-content: start;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }

    &__label {
        display: flex;
        gap: 6px;
        align-items: center;
        color: #333;
        font-family: "Golos Text";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
    }

    &__drop,
    &__drop_hide {
        border: none;
        cursor: pointer;
        background-color: inherit;
        margin-left: auto;

        &_hide {
            transform: rotate(-90deg);
        }
    }

    &__done {
        width: 24px;
        height: 24px;
    }

    &__text {
        text-align: center;
        color: #808080;
        text-align: center;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;

        span {
            color: #00B45E;
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;
    }



    .promo-code {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin-top: 24px;

        .loader_box {
            width: fit-content;
            height: fit-content;
            position: absolute;
            top: calc(50% - 16px);
            right: calc(62px - 12px);
        }

        .text {
            color: var(--all-text-secondary-text, #475569);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4;
        }

        &__container-input {
            display: flex;
            position: relative;

            .input-grey {
                display: flex;
                padding: 8px 12px 8px 8px;
                padding-right: 144px;
                width: 100%;
                min-width: 328px;
                max-width: 328px;
                min-height: 40px;
                border-radius: 8px;
                background: #EDF3F8;
                border: none;
                text-align: center;

                &:placeholder {
                    color: #94A3B8;
                    text-align: center;
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                &:focus {
                    outline: none;
                }
            }

            .text_err {
                position: absolute;
                top: 40px;
                color: #EF4444;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .btn-grey,
            .btn-grey_active {
                width: 124px;
                height: 40px;
                top: 0;
                right: 0;
                position: absolute;
                gap: 8px;
                border-radius: 8px;
                background: #E1E6EA;
                border: none;

                &_active {
                    background-color: white;
                    color: #00B45E;
                    border: 1px solid #00B45E;
                }


            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 40px;
        background-color: white;
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #EDEFED;
        margin-left: auto;
        margin-right: auto;
        min-width: 800px;
        padding: 24px;

        .title_tariffs {
            color: #0C2037;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .cards-group {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex-wrap: wrap;


        .tabs-green {
            margin-left: auto;

            .discount {
                display: flex;
                padding: 1px 4px;
                align-items: center;
                height: 14px;
                gap: 8px;
                border-radius: 2px;
                background: #3A66FF;
                width: auto;
                min-height: 19px;
                color: #767676;
                text-align: center;
                font-family: "Open Sans";
                color: #FFF;
                text-align: center;
                font-family: "Open Sans";
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                white-space: nowrap;
            }
        }

        .cards-min-grouop {
            display: flex;
            border-radius: 4px;
            background: #F5F5F5;
            padding: 4px;
            margin-left: auto;
            width: auto;
            justify-content: center;

            

            .tariff-cards-min,
            .tariff-cards-min_active {
                display: flex;
                width: 100%;
                min-height: 28px;
                padding: 0px 8px;
                align-items: center;
                gap: 4px;
                border-radius: 4px;
                color: #767676;
                text-align: center;
                text-overflow: ellipsis;
                font-family: "Open Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                cursor: pointer;
                justify-content: center;



                p {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    white-space: nowrap;

                    .round {
                        background-color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        border: 1px solid #00B45E;
                        border-radius: 50%;
                    }
                }
            }

            .tariff-cards-min_active,
            .tariff-cards-min:hover {
                transition: 0.3s;
                background-color: #ffffff;

                p {
                    color: #00B45E;

                    .round {
                        span {
                            transition: 0.3s;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #00B45E;
                        }
                    }
                }
            }
        }
    }

    .tariff-cards-group {
        display: flex;
        gap: 28px;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
    }

    .tariff-card {
        display: flex;
        padding: 10px 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px solid #D7D7D7;
        background: #FFF;
        min-height: 190px;
        min-width: 240px;
        max-width: 240px;
        position: relative;

        &__name {
            color: #FF7A00;
            font-family: "Golos Text";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4;
            min-height: 56px;
        }

        &__sum-box {
            color: #000;
            white-space: nowrap;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4;

            span {
                 font-family: "Golos Text" !important;
                 font-weight: 400;
            }
        }

        &__description {
            text-align: left;
            color: #606060;
            font-family: "Golos Text";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 7px;
        }

        &__sticker {
            display: inline-flex;
            padding: 7px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: #FFBB56;
            color: #000;
            font-family: "Golos Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            height: 22px;
            position: absolute;
            top: -13px;
            right: calc(50% - 133px/2);
        }
    }
}