.modal-content-transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    z-index: 50;
    background-color: #fff;
    position: absolute;
    top: 121px;
    padding: 32px;
    width: 100%;
    height: auto;
    max-height: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    position: absolute;
    max-width: 560px;
    top: 121px;
    width: 100%;
    min-width: 360px;

    .title {
        color: #020617;
        text-align: center;
        
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    .img {
        width: 70px;
        height: 70px;
    }

    .counter-product {
        display: flex;
        gap: 4px;
        width: auto;
        padding: 4px;
        align-items: center;
        border-radius: 8px;
        background: #EDEFFB;

        .text {
            color: #8278F0;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }

    .btn-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
    }

    .selector_wrapper {
        max-width: 100%;
    }
}