.multi-level-drop-menu {
    display: flex;
    min-width: 330px;
    max-width: 330px;
    height: fit-content;
    padding: 12px 8px 12px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;


    &__level-1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 56px;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px 8px 0px 0px;
        background: #F0F2F4;

        .text_level-1 {
            display: -webkit-box;
            -webkit-line-clamp: 2; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
            color: #020617;
            
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            cursor: pointer;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 12px 0px;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid #EDEFFB;
    }

    &__inside-content, &__inside-content_hide {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100vh;
        transition: 2s;
        height: 100%;

        .text_level-3 {
            padding: 8px 12px;
            color: #64748B;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        &_hide {
            overflow: hidden;
            transition: 1s;
            max-height: 0px;
        }
    }

    &__level-2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .text-and-icon {
            display: flex;
            width: 100%;
            justify-content: space-between;
            cursor: pointer;
        }

        .btn-drop {
            border: none;
            background-color: inherit;
        }

        .text_level-2 {
            padding: 8px 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #1E293B;
            
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }
}