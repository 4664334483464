.social-media-links {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &__item-bkg {
        display: flex;
        min-width: 257px;
        height: 74px;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        background: #3390EC;
    }

    &__item {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    &__link-name {
        color: #333;
        text-align: center;
        font-family: "Golos Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border-radius: 8px;
    }

    &__links {
        gap: 12px;
        display: flex;
        flex-wrap: wrap;
    }

    &__name-transform {
        
        &::first-letter {
                text-transform: uppercase;
            } 
    }
}