.buttons-sort {
    overflow: hidden;
    display: flex;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #E0E7F2;

    &__btn,
    &__btn-active {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        border: none;
        border-left: 1px solid #E0E7F2;
        background-color: #fff;
        padding: 0 20px;
        color: #020617;
        white-space: nowrap;
        font: 300 12px/14px Rubik;

        path {
            fill: #64748B;
            stroke: #64748B;
        }

        &:hover,
        &-active {
            transition: 0.3s;
            background: #51CA64;
            color: white;

            path {
                fill: #fff;
                stroke: #fff;
            }
        }

        &:first-child {
            border: none;
        }
    }

    &__arrow,
    &__arrow-up {
        width: 16px;
        height: 16px;
    }

    &__arrow-up {
        transform: rotate(180deg);
    }
}