.modal-delivery-creation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1260px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    border-radius: 8px;
    background: #F6F7F6;
    gap: 12px;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    height: 100%;

    .title {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 27px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
    }

    &__content {
        display: flex;
        gap: 8px;
        width: 100%;
        height: calc(100% - 46px);
        justify-content: flex-start;

        .deliveries-list,
        .boxes,
        .goods-in-box {
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 8px;
            background: #FFF;
            width: 242px;
            min-width: 242px;
            height: 100%;

            &__header {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 14px;
                padding: 8px 20px;

                .title {
                    color: #0B110B;
                    font-family: NunitoSans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;

                    span {
                        font-weight: 800;
                    }
                }

                .btn-group {
                    display: flex;
                    gap: 12px;

                    .btn-print {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        border-radius: 8px;
                        background: #E1E6E2;
                        border: none;
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 8px 20px;
                padding-right: 8px;
            }

            &__checkbox-group {
                display: flex;
                flex-direction: column;
                align-items: start;
                width: 100%;
                gap: 16px;

                .radio-box {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                span {
                    color: #000000;
                    text-align: center;
                    font-family: NunitoSans;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px
                }
            }
        }

        .boxes,
        .goods-in-box {
            width: calc(50% - 16px - 242px/2);
        }
    }

    .deliveries-list__content {
        padding-right: 8px;
    }

    .boxes__content {
        padding-right: 0px;
        gap: 12px;
    }

    .goods-in-box__content {
        padding-right: 4px;
        gap: 16px;
    }



    .card-collect {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        background: #FFF;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #EDEFED;
        border-radius: 12px;

        &__main-content {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .collect_box {
            display: flex;
            width: 100%;
            gap: 20px;

            .img-box {
                min-width: 100px;
                width: 100px;
                height: 100px;

                .collect_info_img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            .text_info_box {
                min-width: 100px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 100%;
                flex-direction: column;

                .title-and-btn {
                    display: flex;
                    gap: 24px;
                }

                .text_info_name {
                    display: -webkit-box;
                    -webkit-line-clamp: 2; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                    font-family: NunitoSans;
                    overflow: hidden;
                    color: #636D65;
                    text-overflow: ellipsis;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    min-height: 32px;
                }

                .text_info_desc {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-family: NunitoSans;
                    overflow: hidden;
                    color: #B147E2;
                    text-overflow: ellipsis;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                }

                .text_info_brand {
                    overflow: hidden;
                    color: #DB8E34;
                    text-overflow: ellipsis;
                    font-family: NunitoSans;
                    text-overflow: ellipsis;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                }


                .text_info_sub_desc_box {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    gap: 10px;
                    flex-wrap: nowrap;
                    width: 100%;

                    .text_sum {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #0B110B;
                        font-family: NunitoSans;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 12px;
                        border-radius: 4px;
                        background: #F0F2F4;
                        height: 20px;
                        padding-left: 4px;
                        padding-right: 4px;

                        span {
                            color: #B4B8B4;
                            font-family: NunitoSans;
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 12px;
                        }
                    }

                    .text_info_sub_desc {
                        color: #B4B8B4;
                        font-size: 11px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; // количество строк
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        hyphens: auto;
                        width: 100%;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        margin-top: 4px;

                        span {
                            color: #101810;
                        }
                    }

                    .button-copy {
                        position: relative;
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .context-more {
                background-color: #F4F7F4;
                border-radius: 8px;
            }
        }
    }
}

.card-delivery,
.card-delivery_active {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Stroke-Primary, #EDEFED);
    background: #FFF;
    padding: 12px;
    cursor: pointer;

    &_active {
        border: 1px solid #22C55E;
    }

    &:hover {
        border: 1px solid #22C55E;
    }

    .title {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        word-break: break-word;
    }

    .input {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        border: none;
        padding: 0;

        &:focus {
            color: #0B110B;
            border: none;
            outline: none;
        }

        &:placeholder {
            color: #B4B8B4;
        }
    }

    .btn-group {
        display: flex;
        align-items: center;
        gap: 4px;

        .context-more {
            background-color: #F4F7F4;
            border-radius: 8px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        .text {
            color: #636D65;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;

            span {
                color: #0B110B;
                font-weight: 700;
            }
        }

        .text_date {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
        }
    }
}

.card-boxes,
.card-boxes_active {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    background: #FFF;
    padding: 12px;
    cursor: pointer;
    border-left: 2px solid #FFF;

    &:hover {
        transition: 0.5s;
        background-color: #F9FBF9;
    }

    &_active {
        transition: 0.5s;
        background-color: #F9FBF9;
        border-left: 2px solid #57CC6A;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
    }

    .icon-and-title {
        display: flex;
        gap: 12px;
    }

    .title {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }

    .input {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        border: none;
        padding: 0;
        background-color: inherit;

        &:focus {
            background-color: inherit;
            color: #0B110B;
            border: none;
            outline: none;
        }

        &:placeholder {
            color: #B4B8B4;
        }
    }

    .text {
        color: #636D65;
        font-family: NunitoSans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        span {
            padding-left: 4px;
            padding-right: 4px;
            border-radius: 4px;
            background: #E3EAF4;
            overflow: hidden;
            color: #020617;
            text-overflow: ellipsis;
            font-family: "Open Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            height: 20px;
        }
    }

    .context-more {
        background-color: #F4F7F4;
        border-radius: 8px;
    }
}