.add-media-product {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 12px;

    .img-content,
    .video-content {
        display: inline;
        width: 100%;
        gap: 18px;

        .img-box,
        .img-box_big,
        .video-box {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 84px;
            width: 84px;
            height: 88px;
            border-radius: 8px;
            border: 2px solid #EDEFFB;
            background: inherit;
            overflow: hidden;
            cursor: pointer;
            position: relative;

            .close-box {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;

                .close-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    z-index: 1;
                }
            }

            &:hover {
                .close-box {
                    transition: 0.3s;
                    opacity: 1;
                }
            }

            &_big {
                min-width: 175px;
                width: 175px;
                height: 183px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .not-img {
                width: 24px;
                height: 24px;
            }
        }

        .img-group,
        .video-group {
            display: inline-flex;
            gap: 12px;
            flex-wrap: wrap;
        }
    }

    .text_sum-photo {
        color: #475569;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        span {
            @extend .text_sum-photo;
            color: #8F99A3;
        }
    }

    .content-photo-btn {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 16px;
        align-items: center;
    }

}