.products-in-campaign {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;

  .title_main {
    color: #020617;
    
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .child-table {
    display: flex;
    align-items: center;
    gap: 12px;

    .btn-group {
      display: flex;
      gap: 12px;
      width: 100%;
      justify-content: end;
    }
  }

  .content-btn {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .metrics-items {
    width: fit-content;
    display: flex;
    width: fit-content;
    gap: 12px;
    flex-wrap: wrap;
  }

  .filter-and-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .filter-group {
      display: flex;
      justify-content: start;
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
