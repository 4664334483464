.modal-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 760px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__content {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
    }

    .btn-close{
        background-image: url('./img/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        background-color: inherit;
        top: 10px;
        right: 10px;
        position: absolute;
    }

    .title {
        color: var(--text-primary-blue, #020617);
        text-align: center;
        font-family: "Open Sans";
        font-size: 15px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text_grey-blue {
        color: var(--text-blue-grey, #94A3B8);
        /* Button link/Medium */
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 12px;
    }

    img {
        width: 110px;
        height: 110px;
        margin: 24px;
    }

    .group-btn {
        width: 100%;
        display: flex;
        gap: 8px;
        margin-top: 48px;

        .btn__white, .btn__blue {
            max-width: 100%;
            width: calc(50% - 4px);
        }

        .btn__white {
            color: #1890FF;
            border: none;
        }
    }
}