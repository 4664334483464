.payment {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
  
    .content-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  
    &__content {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
    }
  
    &__balance,
    &__promocode {
      width: calc(50% - 6px);
      min-width: 470px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 10px;
      border: 1px solid #EDEFED;
      background: #FFF;
    }
  
    &__balance-content {
      display: flex;
      height: 73px;
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #EDEFED;
      background: #F6F8FF;
    }
  
    &__promocode-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 2px;
    }
  
    &__sum {
      white-space: nowrap;
      color: #365EFF;
      font: 600 29px/1 "Open sans", sans-serif;
    }
  
    &__placeholder {
      color: #0C2037;
      font: 500 15px/24px "Open sans", sans-serif;
    }
  
    &__input-box {
      display: flex;
      width: 100%;
      padding: 8px 16px;
      justify-content: space-between;
      border-radius: 10px;
      border: 1px solid #EDEFED;
      background: #FFF;
      gap: 24px;
    }
  
    &__input {
      width: calc(100% - 174px); // 150px button + 24px gap
      color: #000;
      font: 400 15px/24px "Open sans", sans-serif;
      border: none;
  
      &::placeholder {
        color: #A5A6A8;
      }
  
      &:focus {
        outline: none;
      }
    }
  }