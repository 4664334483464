.btn__social-media {
    cursor: pointer;
    display: flex;
    gap: 16px;
    width: calc(50% - 6px) ;
    height: 64px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: #020617;
    border: 1px solid #EDEFFB;
    max-width: 274px;

    &_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &_text {
        color: #000;
        font-family: "Open Sans";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &_yandex {
        border-radius: var(--Main-System-10px, 10px);
        border: 1px solid #FF5226;
    }

    &:hover {
        box-shadow: 2px 3px 12px 0px rgba(0, 0, 0, 0.04);
        transition: all 0.3s;
    }

    img {
        display: inline-block;
    }
}

.btn-group__social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    max-width: 560px;
}