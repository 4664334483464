.modal-сreate-group {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1088px;
    width: 100%;
    min-width: 360px;
    height: 100%;
    max-height: 700px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        padding: 24px;
        width: 100%;

        .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #020617;
            font-family: NunitoSans;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }
    }

    .tabs {
        padding: 12px 24px 0 24px;
    }

    .toolbar-medium {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        padding: 24px;
        border-top: 1px solid #EDEFED;
        border-bottom: 1px solid #EDEFED;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 24px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        max-height: calc(100% - 96px - 84px - 32px);
        row-gap: 28px;
        column-gap: 12px;
    }

    .input-field-wrapper {
        max-width: 100%;
    }

    .no-executor {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 100%;

        .text_grey {
            text-align: center;
            color: #94A3B8;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    &__group-btn {
        display: flex;
        width: 100%;
        padding: 14px 24px;
        justify-content: end;
        border-top: 1px solid #EDEFED;
        margin-top: auto;
    }
}