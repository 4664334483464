.project-for-tasks {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  height: 100%;

  .employees-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .sum_executor {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #F1F5F9;
      color: #2E3552;
      font-family: NunitoSans;
      font-size: 10px;
      font-weight: 800;
      line-height: 8px;
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;
  }

  .toolbar-bottom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    padding: 12px 24px;
    background-color: #fff;
    border-radius: 8px;
    border-bottom: 1px solid #EDEFED;

    .btn-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
    }

    .btn-executor-content {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__box-card,
  &__list-card {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;

    .no-data-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 30px;

      .title {
        color: #0F172A;
        
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
      }

      .text {
        color: #68727D;
        
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  &__list-card {
    gap: 8px;
    flex-direction: column;
  }

  .list-item {
    display: flex;
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #E0E7F2;
    height: 60px;
    width: 100%;

    &:hover {
      background-color: #E7F1FF;
      border-color: #1890FF;
    }

    &__header {
      display: flex;
      padding: 8px 16px;
      min-width: 369px;
      align-items: center;
      gap: 12px;

      .title {
        color: #020617;
        
        font-size: 15px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 45px;
        border-radius: 4px;
        border: 1px solid #E0E7F2;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding-right: 16px;

      .sum-and-date {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 0 22px;
        min-width: 250px;
        border-right: 1px solid #E0E7F2;
        border-left: 1px solid #E0E7F2;
        height: 45px;
      }

      .sum-task,
      .date-box {
        display: flex;
        align-items: center;
        gap: 4px;

        img {
          width: 16px;
          height: 16px;
        }

        .text {
          color: #8F99A3;
          font-family: "Open Sans";
          font-size: 11px;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .date-of-creation .text {
        color: #8F99A3;
        font-family: "Open Sans";
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
}

.widget-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00B45E;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    
    /* Анимация подскакивания (1 раз) */
    animation: bounce 0.8s ease-out 1 forwards;
  
    &:hover {
      background-color: #199547;
    }
  
    img {
      width: 24px;
      height: 24px;
    }
  }
  
  /* Ключевые кадры анимации */
  @keyframes bounce {
    0% {
      transform: translateY(100px) scale(0.8);
      opacity: 0;
    }
    40% {
      transform: translateY(-20px) scale(1.1);
    }
    70% {
      transform: translateY(10px) scale(0.95);
    }
    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }