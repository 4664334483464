.button-action-drop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
    // Base button styles
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 8px;
      border: none;
      background: #FAFBFA;
      cursor: pointer;
  
      // Medium size variant
      &-medium {
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 4px;
      }
  
      // Small size variant
      &-small {
        width: 16px;
        height: 16px;
        min-width: 16px;
        border-radius: 4px;
  
        img {
          width: 14px;
          height: 14px;
          transform: rotate(90deg);
        }
      }
    }
  
    // Dropdown menu (hidden by default)
    &__drop-menu, &__drop-menu_active {
      position: fixed;
      width: 140px;
      padding-top: 6px;
      z-index: 10; // Increased for better layering
      visibility: hidden;
      opacity: 0;
      
      // Active state
      &_active {
        visibility: visible;
        opacity: 1;
      }
  
      // Dropdown container
      &-inside {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        background: #fff;
        box-shadow: 0 4px 4px rgba(30, 41, 59, 0.06);
        padding: 4px 0;
      }
  
      // Menu items
      &__btn {
        width: 100%;
        min-height: 32px;
        padding: 8px;
        border: none;
        background-color: transparent;
        text-align: left;
        color: #3A3A3A;
        font: 400 13px/14px 'NunitoSans', sans-serif;
        cursor: pointer;
  
        &:hover {
          background-color: #F3F3F3;
        }
      }
    }
  }