.modal-cover-project {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 1000;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    width: 100%;
    min-width: 360px;
    background-color: white;
    border-radius: 8px;
    max-height: calc(100vh - 120px);
    justify-content: space-between;
    position: absolute;
    z-index: 1001;

    &__header {
        display: flex;
        gap: 12px;
        padding: 4px 20px;
        width: 100%;
        border-radius: 4px 4px 12px 12px;
        border: 4px solid #FFF;
        background: var(--info-bg, #E7F1FF);

        .title {
            color: #404E8C;
            font-family: "Open Sans";
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }

        .btn-back {
            background: inherit;
            border: none;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 0px 24px 24px;
        overflow: hidden;

        .img-content {
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 12px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
              }

            .img-box, .img-box_active {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 296px;
                min-width: 296px;
                height: 140px;
                cursor: pointer;
                border: 2px solid #E0E7F2;    
                border-radius: 4px;

                &:hover, &_active{
                    transition: 0.3s;
                    border: 2px solid #95CBFE;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}