   .token-tab {
       display: flex;
       flex-direction: column;
       gap: 16px;
       width: 100%;

       .tokens-row {
           display: flex;
           align-items: center;
           width: 100%;
           gap: 24px;

           &__date {
               color: #585858;
               font-size: 12px;
           }

           &__name,
           &__tools {
               display: flex;
               align-items: center;
               width: 100%;
               gap: 12px;
           }

           &__name-label {
               overflow: hidden;
               color: #020617;
               text-overflow: ellipsis;
               font-family: "Open Sans";
               font-size: 14px;
               font-style: normal;
               font-weight: 600;
               line-height: 140%;
           }
       }
   }