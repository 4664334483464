@import '../../common/styles/svg_img.scss';

.network-groups {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    &__toolbar {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-wrap: wrap;
    }

    &__icon-group {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid white;

        &:nth-child(n + 2) {
            margin-left: -6px;
        }
    }

    .btn-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__card-group {
        display: flex;
        gap: 14px;
        width: 100%;
        flex-wrap: wrap;

        .drop-and-btn {
            position: relative;

            .btn-points {
                background-color: inherit;
                border: none;
            }

            .drop-content {
                display: flex;
                right: -20px;
                flex-direction: column;
                background-color: white;
                border: 2px solid #F2F2F4;
                border-radius: 4px;
                padding: 12px 16px;
                width: 137px;
                height: auto;
                position: absolute;
                top: 24px;

                &__item {
                    color: #0C2037;
                    
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
        }

        .add-group {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 352px;
            border: 2px solid #dadee9;
            border-radius: 10px;
            background: #FFF;
            min-height: 150px;
            max-height: 150px;
            gap: 10px;
            color: #697A8F;
            
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            transition: .3s;
        }

        .add-group:hover,
        .card-group-item:hover {
            background-color: #f1f4fd;
        }

        .card-group-item {
            display: flex;
            width: 352px;
            padding: 14px;
            flex-direction: column;
            transition: .3s;
            align-items: flex-start;
            gap: 10px;
            border-radius: 10px;
            border: 2px solid #F3F6FF;
            background: #FFF;
            min-height: 150px;
            max-height: 150px;
            cursor: pointer;

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 8px;

                .title {
                    color: var(--Base-Black, #000);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    margin: 0;
                }

                


            }

            .text_date-create {
                color: var(--icons-default, #B2BACC);
                
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-top: 6px;
            }

            .icon-group {
                display: flex;
                align-items: center;

                .icon {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    border-radius: 50px;
                    margin-left: -8px;

                    &:nth-child(1) {
                        margin-left: 0px;
                    }
                }
            }

            .status_active,
            .status_disactive {
                display: flex;
                height: 22px;
                padding: 0 8px;
                align-items: center;
                border-radius: 4px;
                background: #BBF7D0;
                color: #166534;
                text-align: center;
                
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
            }

            .status_disactive {
                color: #94A3B8;
                background-color: #F1F5F9;
            }
        }
    }

    .card-item {
        cursor: pointer;
        width: 240px;
        min-width: 240px;
        background: #FFF;
        border: 1px solid #E0E7F2;
        border-radius:  8px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &__icons {
            display: flex;
        }

        &__sum {
            display: flex;
            width: 32px;
            height: 32px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #F1F5F9;
            color: rgba(46, 53, 82, 0.60);
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 8px;
            margin-left: 8px;
        }

        &:hover {
            transition: 0.3s;
            background-color: #E7F1FF;
            border: 1px solid #1890FF;

            .card-item__header {
                transition: 0.3s;
                background-color: #F5F9FF;
            }
        }

        &__top-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 12px;
            border-radius: 8px 8px 0px 0px;
            background: #F8F9FC;

            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 112px;
                min-height: 112px;
                width: 100%;
                border-radius: 8px;
                overflow: hidden;

                .img {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                }
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            width: auto;
            align-items: center;
            gap: 8px;

            .statistic-tasks {
                display: flex;
                gap: 16px;
                overflow: hidden;
                flex-grow: nowrap;

                &__item {
                    padding: 2px 4px;
                    align-items: flex-start;
                    white-space: nowrap;
                    border-radius: 4px;
                    text-align: center;
                    color: var(--Slate-500, #64748B);
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;

                    span {
                        color: #020018;
                        font-weight: 600;
                    }
                }
            }


        }

        &__medium-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 12px;
            width: auto;

            .title {
                color: #020617;
                font-family: NunitoSans;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                min-height: 40px;
                letter-spacing: 0.08px;
                display: -webkit-box;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
            }

            .date_project {
                color: #64748B;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.12px;
            }
        }

        &__bottom-content {
            display: flex;
            flex-direction: column;
            padding: 12px;
            margin-top: auto;
            border-top: 1px solid #EDEFFB;

            .sum-task,
            .date-box {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    width: 16px;
                    height: 16px;
                }

                .text {
                    color: #8F99A3;
                    font-family: "Open Sans";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .days-left {
                    margin-left: auto;
                    display: flex;
                    padding: 0px 2px;
                    min-height: 8px;
                    align-items: center;
                    gap: 8px;
                    border-radius: 6px;
                    background: #FFF0F0;
                    color: var(--red-300, #FCA5A5);
                    font-family: "Open Sans";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;

                    span {
                        color: #F93232;
                    }
                }
            }

            .date-of-creation {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                margin-left: auto;

                .text {
                    color: #94A3B8;
                    font-family: NunitoSans;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: 0.05px;
                }

                .text_date {
                    @extend .text;
                    color: #020617;
                }
            }
        }

        .box-title {
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            height: 55%;
            width: 100%;
            border-radius: 16px;
            border-top: 1px solid #f0f0f0;
            overflow: hidden;

            .title-box {
                max-width: 100%;
                overflow: hidden;

                .title {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }


            .group-btn {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

}