.edit-article-wrapper {
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: flex-start;
    position: relative;

    &__drag {
        position: absolute;
        left: -20px;
        margin-top: 6px;
        cursor: grab;
    }

    &__close {
        cursor: pointer;
        margin-top: 6px;
        width: 10px;
        min-width: 10px;
        height: 10px;
        margin-left: auto;
        background-color: rgb(0, 0, 0);
        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    }

    &__text {
        padding-bottom: 24px;
    }

    &__title {
        padding: 32px 0px;

        .edit-article-wrapper {
            &__drag {
                margin-top: 20px;
            }

            &__close {
                margin-top: 20px;
            }
        }
    }

    &__social {
        padding: 16px 0;

        .edit-article-wrapper {
            &__drag {
                margin-top: 0px;
            }

            &__close {
                margin-top: 0px;
            }
        }
    }

    &__info, &__image, &__video {
        margin: 24px 0;

        .edit-article-wrapper {
            &__drag {
                margin-top: 0px;
            }

            &__close {
                margin-top: 0px;
            }
        }
    }

    &__form {
        margin: 24px 0;

        .edit-article-wrapper {
            &__drag {
                margin-top: 0px;
            }

            &__close {
                margin-top: 0px;
            }
        }
    }
}

.edit-article-wrapper {
    transition: background-color 0.3s ease;
}

.edit-article-wrapper--highlight {
    background-color: rgba(0, 123, 255, 0.1); // Голубой фон при наведении
}