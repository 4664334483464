.comment {
    display: flex;
    gap: 8px;
    background-color: #FFF;
    border-radius: 6px;
    padding: 12px 12px;
    border-radius: 6px;

    .editor-component {
        flex-direction: row;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;
    }

    &__executor-content {
        min-width: 32px;
        width: 32px;
        height: 32px;
    }

    &__input-field {
        width: 100%;

        input {
            width: 100%;
            min-height: 40px;
            border-radius: 8px;
            //   border: 1px solid #E1E6EA;
            border: none;
            background: #FFF;
            padding-left: 12px;
            padding-right: 12px;
            color: #8F99A3;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            font-family: "Open Sans";

            &::placeholder {
                font-family: "Open Sans";
                color: #8F99A3;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            &:focus {
                border: 1px solid #E1E6EA;
                outline: none;
            }
        }

        input[disabled] {
            border: 1px solid #F8F9F8;
            background: #F8F9F8;
            cursor: not-allowed;
        }
    }
}

.comment-answer {
    display: flex;
    flex-direction: column;
    background: #F2F2F2;
    padding: 8px 12px;

    &__author {
        color: #00B45E;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    &__text {
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        & p {
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}