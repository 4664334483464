.dynamics-component {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 540px;
    min-width: 540px;
    height: 100%;
    border-radius: 8px;
    background: #F7F7F7;
    padding: 18px 22px;

    .no-data {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 22px;
    }

    .title {
        color: #0B110B;
        font-family: "Open Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .row-item {
            display: flex;
            gap: 12px;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid #D9D9D9;
            align-items: end;

            .title {
                color: #0B110B;
                font-family: "Open Sans";
                font-size: 12.922px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.615px;
                width: calc(100%/3);
                display: -webkit-box;
                -webkit-line-clamp: 3; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
                word-break: break-word;
            }

            .data-item {
                display: flex;
                flex-direction: column;
                width: calc(100%/3);
                gap: 8px;

                &__top,
                &__bottom {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

                .text {
                    color: #0B110B;
                    font-family: "Open Sans";
                    font-size: 12.922px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16.615px;
                }

                &__bottom {
                    .text {
                        font-size: 11.076px;
                        font-weight: 400;
                    }
                }

                .dynamic_neutral,
                .dynamic_positive,
                .dynamic_negative,
                .dynamic_neutral {
                    display: flex;
                    padding: 2px 4px;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    width: fit-content;
                    border-radius: 4px;
                    color: #656565;
                    background: #D3D3D3;
                    font-family: "Open Sans";
                    font-size: 12.554px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16.739px;
                }

                .dynamic_positive {
                    color: #218931;
                    background: #E1F9E5;
                }

                .dynamic_negative {
                    color: #DA0000;
                    background: #F9E1E1;
                }

                .dynamic_neutral {
                    background-color: #E1EBF9;
                    color: #316DE6;
                }
            }
        }
    }
}