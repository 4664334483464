.card-item-account,
.card-item-account_active {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    border-radius: var(--base-radius, 8px);
    border: 2px solid var(--stroke-primary-primary, #E0E7F2);
    background: #FFF;
    cursor: pointer;
    position: relative;

    &:hover, &_active {
        border: 2px solid #3900B1;
    }
}