.creating-an-advertising-campaign {
    display: flex;
    flex-direction: column;
    max-width: 1021px;
    gap: 16px;
    width: 100%;
    height: 100%;

    .content-btn-stop {
        display: flex;
        max-width: calc(176px - 48px) ;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        gap: 40px;
    }

    .content-btn-start {
        display: flex;
        width: 100%;
        max-width: calc(240px) ;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        gap: 8px;
    }

    .link-breadcrumbs {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #53616E;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .input-box {
        margin-top: 0;
    }

    .input-box__clean-value {
        top: calc(50% - 6px);
    }

    &__main-content {
        display: flex;
        align-items: stretch;
        width: 100%;
        gap: 16px;
        height: fit-content;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 32px;
        gap: 24px;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        background: #F5F7F8;
        min-width: 650px;

        .title {
            color: #020617;
            font-family: "Open Sans";
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .input-field-group {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 32px;

            .MuiFormControl-root {
                margin-top: 0;
            }

            .double-group {
                display: flex;
                align-items: center;
                gap: 12px;

                .input-field-item {
                    display: flex;
                    flex-direction: column;
                    width: calc(50% - 6px);
                    gap: 12px;

                    .component-date {
                        display: flex;
                        height: 56px;
                        padding: var(--space-x2, 8px) var(--space-x5, 20px);
                        align-items: center;
                        gap: var(--space-x2, 8px);
                        align-self: stretch;
                        border-radius: 8px;
                        border: 1px solid var(--table-border, #E0E7F2);
                        background: var(--white, #FFF);
                        color: var(--all-text-teritary-text, #94A3B8);
                        /* Fields/Text placeholder/M */
                        font-family: "Open Sans";
                        font-size: 13px;
                        font-style: normal;
                        line-height: 16px;
                    }
                }

                .title {
                    color: var(--all-text-secondary-text, #475569);
                    font-family: "Open Sans";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 150% */
                }
            }
        }

        .chosen-strategy {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            gap: 8px;
          
            .text-grey {
                color: var(--text-teritary-black, #8F99A3);
                font-family: "Open Sans";
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .text {
                color: var(--all-text-primary-text, #020617);
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .btn-group {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;
        }
    }

    &__side-panel {
        display: flex;
        flex-direction: column;
        min-width: 224px;
        max-width: 224px;
        padding: 20px;
        gap: 28px;
        border-radius: 8px;
        background: #ffffff;
        border: 1px solid #E0E7F2;

        .step-item {
            display: flex;
            gap: 12px;
            height: auto;
            cursor: pointer;

            .img-container {
                width: 24px;
                min-width: 24px;
                min-height: 24px;
                height: 24px;
            }

            .text {
                color: var(--text-secondary-black, #53616E);
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .text_grey {
                color: var(--text-teritary-black, #8F99A3);
                font-family: "Open Sans";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .table-container {
        display: flex;
        margin-top: 16px;
        width: 100%;
        flex-direction: column;
        gap: 16px;

        .btn__white-blue {
            min-height: 40px;
            min-width: 170px;
            max-width: 170px;
        }
    }
}