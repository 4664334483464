.table-without-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding-left: 60px;
        padding-right: 60px;

        img {
            width: 45px;
            height: 45px;
        }

        .text {
            color: var(--all-text-teritary-text, #94A3B8);
            text-align: center;
            font-family: "Open Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }
}